import { useState } from "react";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import BottomNavigation from "../../component/BottomNavigation"; // Import the BottomNavigation component
import styled from "styled-components";

const LayoutContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  width: 100%;
  margin-left: 30px;
  height: 100vh;
  display: flex;
  flex-direction: column; /* Ensure content and BottomNavigation are stacked vertically */

  @media (max-width: 768px) {
    max-width: calc(100vw - 110px);
  }

  @media (max-width: 425px) {
    margin-left: 0px;
    max-width: 100vw;
  }
`;

const ContentWrapper = styled.div`
  flex: 1; /* Allows the main content to take up the remaining space */
  overflow-y: auto; /* Enables scrolling if content overflows */
`;

const MainLayout = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <LayoutContainer>
      <Sidebar open={open} setOpen={setOpen} />
      <MainContent>
        <Header setOpen={setOpen} />
        <ContentWrapper>
          <div className="main">{props.children}</div>
        </ContentWrapper>
        <BottomNavigation /> {/* Add BottomNavigation here */}
      </MainContent>
    </LayoutContainer>
  );
};

export default MainLayout;
