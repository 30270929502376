import React, { useState } from 'react';
import LoaderComponent from './LoaderComponent';
import { PublicKey, Commitment } from '@solana/web3.js';

import { isSolAddress, getMetadataPda } from '../hooks/helper';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { deserializeMetadata } from '@metaplex-foundation/mpl-token-metadata';
import { AccountLayout, TOKEN_PROGRAM_ID, TOKEN_2022_PROGRAM_ID } from '@solana/spl-token';
import { uint8ArrayToNum, getTokenNameSymbol } from '../hooks/helper';

export default function TokenInput(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  let { setValue, value } = props;
  const { connection } = useConnection();
  const { wallet, publicKey } = useWallet();
  const handleInput = async (e) => {
    if (!publicKey) {
      setError("Please connect wallet!");
      return;
    }
    let tokenAddress = e.target.value;
    setIsLoading(true);
    try {
      if (isSolAddress(tokenAddress)) {
        const tokenMint = new PublicKey(tokenAddress);
        const mint = await connection.getTokenSupply(tokenMint);
        const tokenDecimal = mint.value.decimals;
        let tokenMetaData = await getTokenNameSymbol(connection, tokenMint);
        let tokenName = '';
        let tokenSymbol = '';
        if (tokenMetaData == null) {
          setError(
            "Not a metaplex standard token!"
          );
        } else {
          tokenName = tokenMetaData.tokenName;
          tokenSymbol = tokenMetaData.tokenSymbol;
        }
        const tokenAccounts = await connection.getTokenAccountsByOwner(
          publicKey,
          {
            programId: TOKEN_PROGRAM_ID,
          }
        );
        const tokenAccount = tokenAccounts.value.map((tokenAccount) => {
          const accountData = AccountLayout.decode(tokenAccount.account.data);
          // console.log(`${new PublicKey(accountData.mint)}   ${accountData.amount}`, accountData);
          return accountData
        }).find(ele => String(new PublicKey(ele.mint)) === tokenAddress);
        if (!tokenAccount?.amount) { setError("No Balance"); return; };

        let maxAmount = tokenAccount.amount;
        maxAmount = uint8ArrayToNum(maxAmount);
        setValue({ ...value, ispoolExist : false ,"tokenAddress": e.target.value, tokenName, tokenDecimal, tokenSymbol, maxAmount });
        setError('');
        setIsLoading(false);
      }
      else {
        setValue({ ...value, ispoolExist : false , "tokenAddress": '', tokenName: "", tokenDecimal: "", tokenSymbol: "", isApprove: false });
        setError('Please enter a valid address !');
      }
    } catch (e) {
      console.log(e.reason)
    }
    // setIsLoading((prev) => !prev);
    return false;
  }

  return (
    <div className="col-md-12">
      <div className="form-group">
      <label>Token address <span className='text-danger'>*</span></label>
      <input className="form-control" type="text" name="name" placeholder="" onChange={(e) => { handleInput(e) }} />
      <small className='text-danger'>{error}</small>
      </div>

      {isLoading === true ? (<LoaderComponent status={isLoading} />) : (
      value.tokenName !== '' && value.tokenDecimal && value.tokenSymbol && value.tokenAddress &&
      <React.Fragment>
          <div className='mt-3 d-flex justify-content-between card-span'>
          <span>Name</span>
          <span>{value.tokenName}</span>
          </div>
          <div className='mt-2 d-flex justify-content-between card-span'>
          <span>Symbol</span>
          <span>{value.tokenSymbol}</span>
          </div>
          <div className='mt-2 d-flex justify-content-between card-span'>
          <span>Decimal</span>
          <span>{value.tokenDecimal}</span>
          </div>
      </React.Fragment>
      )}
    </div>
  )
}