import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../launchpadApply/index.css";
import { BaseScanUrl } from "../../hooks/constant";
import { CopyToClipboard } from "react-copy-to-clipboard";
import successImage from '../../images/Success.png';

export default function TokenDetails(props) {
  const [copied, setCopied] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tokenAddress = params.get("addr");
  const scanUrl = `${BaseScanUrl}address/${tokenAddress}`;

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const desktopButtonStyle = {
    backgroundColor: "#8e44ad",
    color: "#fff",
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 300,
    fontSize: "14px",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    margin: "0 5px",
    transition: "background-color 0.3s ease",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  const mobileButtonStyle = {
    backgroundColor: "#8e44ad",
    color: "#fff",
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 300,
    fontSize: "12px",
    border: "none",
    padding: "8px 16px",
    borderRadius: "5px",
    margin: "5px 0",
    transition: "background-color 0.3s ease",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  return (
    <>
      <style>{`
        .desktop-view, .mobile-view {
          padding-top: 0; /* Remove any extra padding at the top */
          margin-top: 0; /* Remove any extra margin at the top */
        }

        /* Default to Desktop View */
        .desktop-view {
          display: block;
        }

        .mobile-view {
          display: none;
        }

        /* Mobile View */
        @media (max-width: 768px) {
          .desktop-view {
            display: none;
          }

          .mobile-view {
            display: block;
          }
        }
      `}</style>

      {/* Desktop View */}
      <section className="desktop-view">
        <div className="container px-3">
          <div className="my-5">
            <div className="card stepcard">
              <div className="card-body">
                <h5 className="text-center" style={{ marginBottom: '10px' }}>
                 TOKEN CREATED SUCCESSFULLY!
                </h5>
                <div className="text-center" style={{ margin: '10px 0' }}>
                  <img src={successImage} alt="Success" style={{ maxWidth: '300px', margin: '0px' }} />
                </div>
                <h5 className="text-center" style={{ marginTop: '10px' }}>
                {tokenAddress}
                </h5>

                <div className="d-flex justify-content-center">
                  <CopyToClipboard text={tokenAddress} onCopy={handleCopy}>
                    <button style={desktopButtonStyle}>
                      {copied ? "Copied" : "Copy Address"}
                    </button>
                  </CopyToClipboard>

                  <a
                    className="btn"
                    href={`${scanUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={desktopButtonStyle}
                  >
                    View on Solana Explorer
                  </a>

                  <Link
                    className="btn"
                    to="/fairlaunch"
                    style={desktopButtonStyle}
                  >
                    Create Fairlaunch
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile View */}
      <section className="mobile-view">
        <div className="container px-3">
          <div className="my-5">
            <div className="card stepcard">
              <div className="card-body">
                <h5 className="text-center" style={{ marginBottom: '5px' }}>
                  TOKEN CREATED!
                </h5>
                <div className="text-center" style={{ margin: '5px 0' }}>
                  <img src={successImage} alt="Success" style={{ maxWidth: '150px', margin: '0px' }} />
                </div>
                {/* <h6 className="text-center" style={{ marginTop: '5px' }}>
                  <br /><span style={{ fontSize: '12px' }}>{tokenAddress}</span>
                </h6> */}

                <div className="d-flex flex-column align-items-center">
                  <CopyToClipboard text={tokenAddress} onCopy={handleCopy}>
                    <button style={mobileButtonStyle}>
                      {copied ? "Copied" : "Copy Address"}
                    </button>
                  </CopyToClipboard>

                  <a
                    className="btn"
                    href={`${scanUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={mobileButtonStyle}
                  >
                    View on SolScan
                  </a>

                  <Link
                    className="btn"
                    to="/fairlaunch"
                    style={mobileButtonStyle}
                  >
                    Create Fair Launch
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
