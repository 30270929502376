import React, { useState, useContext, useEffect } from "react";
import "../launchpadApply/index.css";
import { useWallet } from '@solana/wallet-adapter-react';

import StandardToken from "./StandardToken";
import LiquidityToken from "./LiquidityToken";

import coinLogo from "../../images/coinLogo.svg"; // Ensure this path is correct
import launchpadIcon2 from "../../images/launchpadIcon2.svg"; // Ensure this path is correct

import Context from "./context/Context";
import { defaultValue } from "./context/defaults";
import { useNavigate } from "react-router-dom"; // Import useNavigate

export default function MainToken() {
  const appContext = useContext(Context);
  const [context, setContext] = useState(appContext);
  const [type, setType] = useState("1");
  const { wallet } = useWallet();
  const navigate = useNavigate(); // Initialize navigate

  const setValue = (value) => {
    setContext({ ...context, value });
  };
  const state = {
    ...context,
    setValue: setValue,
  };
  const [createFee, setCreateFee] = useState(0.05);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const containerStyle = {
    display: 'flex',
    justifyContent: 'flex-start', // Align content to the left
    alignItems: 'flex-start', // Align items to the top
    flexDirection: 'column',
    padding: '20px 0 0 0', // Add padding on top, remove padding on sides and bottom
    margin: '0', // Remove all margin
  };

  const mobileContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0 0 0', // Add padding on top, remove padding on sides and bottom
    margin: '0', // Remove all margin
    alignItems: 'flex-start', // Align items to the top and left
  };

  const contentStyle = {
    width: '100%',
    maxWidth: '1200px',
    padding: '0', // Remove padding from content
    margin: '0', // Remove margin from content
  };

  const mobileContentStyle = {
    width: '100%',
    padding: '0', // Remove padding from content
    margin: '0', // Remove margin from content
  };

  const headerContainerStyle = {
    display: 'flex',
    alignItems: 'center', // Vertically center-align the items
    marginBottom: '0px', // Reduce bottom margin
    marginTop: '0', // Remove top margin
  };

  const mobileHeaderContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center', // Vertically center-align the items
    marginBottom: '0px', // Reduce bottom margin
    marginTop: '0', // Remove top margin
  };

  const logoStyle = {
    width: '30px', // Reduce the size of the logo
    height: '30px',
    marginRight: '10px', // Space between the logo and text
    marginLeft: '30px', // Space between the logo and text
  };

  const mobileLogoStyle = {
    width: '30px', // Reduce the size of the logo for mobile as well
    height: '30px',
    marginRight: '10px', // Space between the logo and text
    marginLeft: '10px', // Space between the logo and text
  };

  const headerTextContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center the text vertically with respect to the logo
    gap: '1.5px', // Smaller gap between header and sub-header
  };

  const headerTextStyle = {
    margin: '0', // Remove any additional margins
    textAlign: 'left', // Align text to the left
  };

  const headerStyle = {
    fontSize: '20px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '600',
    margin: '0', // Remove margin
    padding: '0', // Remove padding
    lineHeight: '0.8', // Adjust line height to bring text closer
    color: '#ffff',
    marginTop: '10px',

  };

  const mobileHeaderStyle = {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#ffff',
    margin: '0', // Remove margin
    padding: '0', // Remove padding
    lineHeight: '1', // Adjust line height to bring text closer
  };

  const subHeaderStyle = {
    textAlign: 'left',
    color: '#7B91B0',  // Updated color
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '400',
    marginBottom: '0px',
  };

  const mobileSubHeaderStyle = {
    fontSize: '10px',
    color: '#888',
    textAlign: 'left', // Align text to the left
    margin: '2px 0 0 0', // Adjust margin to reduce the space between header and subheader
    padding: '0', // Remove padding
  };

  const cardStyle = {
    background: 'transparent', // Make background transparent
    border: 'none', // Remove border
    boxShadow: 'none', // Remove any shadow
    margin: '0', // Remove margin from card
    padding: '0', // Remove padding from card
  };

  // Style objects for the Fair Launch section
  const fairLaunchContainerStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '20px',
    padding: '10px',
    marginTop: '0px',
    marginBottom: '20px',
  };

  const fairLaunchContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    maxWidth: '1400px',
    background: '#111432',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
    border: '1px solid #075985',
  };

  const fairLaunchStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  };

  const fairLaunchIconStyle = {
    width: '30px',
    height: '30px',
    margin: '0 10px',
  };

  const fairLaunchTextStyle = {
    fontSize: '14px',
    color: '#7B91B0',
    textAlign: 'center',
    fontFamily: 'Quicksand, sans-serif', // Updated font to Quicksand
  };

  const fairLaunchHighlightStyle = {
    color: '#2F6EFF',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontFamily: 'Quicksand, sans-serif', // Updated font to Quicksand
  };

  return (
    <Context.Provider value={state}>
      <div className="my-0">
        <div className="card stepcard" style={cardStyle}>
          <div className="card-body" style={cardStyle}>
            <form className="login-box">
              <div className="row">
                <div className="col-12 mb-0">
                  <div className="nav nav-tabs" role="tablist" style={{ border: 'none', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                    <div style={isMobile ? mobileContainerStyle : containerStyle}>
                      <div style={isMobile ? mobileContentStyle : contentStyle}>
                        <div style={isMobile ? mobileHeaderContainerStyle : headerContainerStyle}>
                          <img src={coinLogo} alt="Coin Logo" style={isMobile ? mobileLogoStyle : logoStyle} />
                          <div style={headerTextContainerStyle}>
                            <div style={isMobile ? mobileHeaderStyle : headerStyle}>Create a Token</div>
                            <div style={isMobile ? mobileSubHeaderStyle : subHeaderStyle}>
                              Zero code knowledge. Complete in 60 seconds!
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content mt-3" id="myTabContent">
                    <div className={`tab-pane fade ${type === "1" ? 'show active' : ''}`} id="standard" role="tabpanel" aria-labelledby="standard-tab">
                      {type === "1" && <StandardToken createFee={createFee} />}
                    </div>
                  </div>
                
                  {/* Fair Launch Section */}
                  {!isMobile && (
                    <div style={fairLaunchContainerStyle}>
                      <div style={fairLaunchContentStyle}>
                        <div style={fairLaunchStyle}>
                          <img src={launchpadIcon2} alt="Launchpad Icon" style={fairLaunchIconStyle} />
                          <div style={fairLaunchTextStyle}>
                            After creating your token use our&nbsp;
                            <span style={fairLaunchHighlightStyle} onClick={() => navigate('/fairlaunch')}>
                              Fair Launch
                            </span>
                            &nbsp;app to fund your liquidity pool and project. The automated, decentralized process ensures safety & efficiency. <br></br>Add & lock liquidity, automatically listed on RADIUM V5 DEX, view charts and start trading. All from just $7.
                          </div>
                          <img src={launchpadIcon2} alt="Launchpad Icon" style={fairLaunchIconStyle} />
                        </div>
                      </div>
                    </div>
                  )}
                
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Context.Provider>
  );
}
