import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as anchor from "@coral-xyz/anchor";
import { BN, AnchorProvider, setProvider, Program } from "@coral-xyz/anchor";
import {
  PublicKey,
  Keypair,
  Transaction,
  // LAMPORTS_PER_SOL,
  SYSVAR_RENT_PUBKEY,
  SystemProgram,
  // sendAndConfirmTransaction,
  // SignatureStatus
} from '@solana/web3.js';


import Button from "react-bootstrap-button-loader";

import Context from "./context/Context";
import { feesSetting } from "./context/defaults";

import { CopyToClipboard } from "react-copy-to-clipboard";
import copyIcon from "../../../images/icon.png";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../../hooks/helper";
import { ADD_PRIORITY_FEE, ADMIN_PUBKEY, FAIR_SALE_PROGRAM_ID, GLOBAL_INFO_PUB_KEY, MODIFY_COMPUTE_UNITS } from "../../../hooks/constant";
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from "@solana/spl-token";
import IDL from '../../../json/idl.json';

// import { useAnchorContext } from "../../../contexts/AnchorContext";
import { associatedTokenProgram } from "@metaplex-foundation/js";


export default function Step5() {
  const { value, btnPrevStep } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const { program, addPriorityFee } = useAnchorContext();

  const { connection } = useConnection();
  const { wallet, publicKey, sendTransaction } = useWallet();

  const handleCreateSale = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (wallet) {
        const poolDetails = `${value.logourl}$#$${value.bannerurl}$#$${value.website}$#$$#$${value.facebook}$#$${value.twitter}$#$${value.github}$#$${value.telegram}$#$${value.instagram}$#$${value.discord}$#$${value.reddit}$#$${value.youtube}$#$${value.brief}`;
        const args = {
          softcap: (value.softcap)*(10**9),
          saleToken: (value.saletoken)*(10**(value.tokenDecimal)),
          refundType: Number(value.refundType),
          startTime: (value.starttime),
          endTime: (value.endtime),
          liquidityLockdays: (value.llockup),
          liquidityPercent: (value.liquidity),
          poolDetails: poolDetails,
          tokenDecimal: Number(value.tokenDecimal),
          tokenName: value.tokenName,
          tokenSymbol: value.tokenSymbol,
        }
        const tokenMint = new PublicKey(value.tokenAddress);
        //creating fair_sale PDA
        const [address] = PublicKey.findProgramAddressSync(
          [
            anchor.utils.bytes.utf8.encode("fair-sale"),
            tokenMint.toBuffer(),
            publicKey.toBuffer()
          ],
          FAIR_SALE_PROGRAM_ID
        );
        const [fair_sale_ata] = PublicKey.findProgramAddressSync(
          [
            address.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            tokenMint.toBuffer()
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const [governance_ata] = PublicKey.findProgramAddressSync(
          [
            publicKey.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            tokenMint.toBuffer()
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const provider = new AnchorProvider(connection, Keypair.generate(), {});
        setProvider(provider);
        const programId = FAIR_SALE_PROGRAM_ID;
        const program = new Program(IDL, programId);
        const instruction = await program.methods.createFairSale(
          new anchor.BN(Number(args.softcap)),
          new anchor.BN(Number(args.saleToken)),
          new anchor.BN(Number(args.refundType)),
          new anchor.BN(Number(args.startTime/1000)),
          new anchor.BN(Number(args.endTime/1000)),
          new anchor.BN(Number(args.liquidityLockdays)),
          new anchor.BN(Number(args.liquidityPercent)),
          args.poolDetails,
          new anchor.BN(args.tokenDecimal),
          args.tokenName,
          args.tokenSymbol
        ).accounts({
          fairSale: address, //pda
          governance: publicKey,
          fairsaleGlobalInfo: GLOBAL_INFO_PUB_KEY,
          admin: ADMIN_PUBKEY,
          splMint: tokenMint,
          governanceSplAta: governance_ata,
          fairSaleSplAta: fair_sale_ata,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY
        }).instruction();
        const transaction = new Transaction();
        transaction.add(MODIFY_COMPUTE_UNITS);
        transaction.add(ADD_PRIORITY_FEE);
        transaction.add(instruction);

        const tx = (await sendTransaction(transaction, connection, { skipPreflight: true }).then().catch((e) => { console.log("error while creating fair launch", e); toast.error("Fair launch creting failed!"); setLoading(false) }));
        if (tx) {
          const result = await connection.confirmTransaction({
            signature: tx
          }, 'confirmed');
          if (result.value.err == null) {
            toast.success("Pool Created!");
            navigate('/');
          } else {
            toast.error("Pool Creation Failed!");
          }
        }
        setLoading(false);
      } else {
        toast.error("Please connect wallet!");
        setLoading(false);
      }
    }
    catch (e) {
      console.log("error on creating launch", e);
      setLoading(false);
    }
    
    
  }

  return (
    <div
      className={`tab-pane ${value.step === 5 ? "active" : ""}`}
      role="tabpanel"
      id="step4"
    >
      <h4 className="text-center">Review your information</h4>
      <div className="mt-3 d-flex justify-content-between card-span">
        <span>Total token</span>
        <span className="step-input-value">
          {formatPrice(value.totaltoken)} {value.tokenSymbol}
        </span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Name</span>
        <span className="step-input-value">{value.tokenName}</span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Symbol</span>
        <span className="step-input-value">{value.tokenSymbol}</span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Decimal</span>
        <span className="step-input-value">{value.tokenDecimal}</span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Total tokens for sale</span>
        <span className="step-input-value">
          {value.saletoken} {value.tokenSymbol}
        </span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Sale method</span>
        <span className="step-input-value">Fair Sale</span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Softcap</span>
        <span className="step-input-value">
          {value.softcap} SOL
        </span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Unsold tokens </span>
        <span className="step-input-value">
          {value.refund === "0" ? "Refund" : "Burn"}
        </span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Liquidity</span>
        <span className="step-input-value">{value.liquidity}%</span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Start time</span>
        <span className="step-input-value">
          {new Date(value.starttime).toUTCString()}
        </span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>End time</span>
        <span className="step-input-value">
          {new Date(value.endtime).toUTCString()}
        </span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Liquidity lockup time</span>
        <span className="step-input-value">{value.llockup} days</span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Website</span>
        <span className="step-input-value">{value.website}</span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Logo image URL</span>
        <span className="step-input-value">{value.logourl}</span>
      </div>
      <div className="mt-2 d-flex justify-content-between card-span">
        <span>Banner image URL</span>
        <span className="step-input-value">{value.bannerurl}</span>
      </div>
      {value.facebook && (
        <div className="mt-2 d-flex justify-content-between card-span">
          <span>Facebook</span>
          <span className="step-input-value">{value.facebook}</span>
        </div>
      )}
      {value.twitter && (
        <div className="mt-2 d-flex justify-content-between card-span">
          <span>Twitter</span>
          <span className="step-input-value">{value.twitter}</span>
        </div>
      )}
      {value.github && (
        <div className="mt-2 d-flex justify-content-between card-span">
          <span>Github</span>
          <span className="step-input-value">{value.github}</span>
        </div>
      )}
      {value.telegram && (
        <div className="mt-2 d-flex justify-content-between card-span">
          <span>Telegram</span>
          <span className="step-input-value">{value.telegram}</span>
        </div>
      )}
      {value.instagram && (
        <div className="mt-2 d-flex justify-content-between card-span">
          <span>Instagram</span>
          <span className="step-input-value">{value.instagram}</span>
        </div>
      )}
      {value.discord && (
        <div className="mt-2 d-flex justify-content-between card-span">
          <span>Discord</span>
          <span className="step-input-value">{value.discord}</span>
        </div>
      )}
      {value.reddit && (
        <div className="mt-2 d-flex justify-content-between card-span">
          <span>Reddit</span>
          <span className="step-input-value">{value.reddit}</span>
        </div>
      )}
      {value.youtube && (
        <div className="mt-2 d-flex justify-content-between card-span">
          <span>Youtube Video</span>
          <span className="step-input-value">{value.youtube}</span>
        </div>
      )}

      {value.brief && (
        <div className="mt-2 d-flex justify-content-between card-span">
          <span>Description</span>
          <span className="step-input-value">
            {value.brief.length > 100
              ? `${value.brief.slice(0, 100)}...`
              : value.brief}
          </span>
        </div>
      )}
      <ul className="list-inline text-center">
        <li>
          <button
            type="button"
            className="btn default-btn prev-step mr-4"
            onClick={(e) => btnPrevStep(e)}
          >
            Back
          </button>
        </li>
        <li>
          <Button
            loading={loading}
            variant="none"
            type="button"
            className="btn default-btn next-step"
            onClick={(e) => handleCreateSale(e)}
          >
            Submit
          </Button>
        </li>
      </ul>
      <div className="mt-5">
        <span>
          IMPORTANT: Please exclude our launchpad contract address{" "}
          {FAIR_SALE_PROGRAM_ID.toString()}
          <CopyToClipboard
            text={
              FAIR_SALE_PROGRAM_ID.toString()
            }
          >
            <img style={{ cursor: "pointer" }} src={copyIcon} alt="project" />
          </CopyToClipboard>{" "}
          from fees, rewards and max tx amount. If not the sale will not
          finalize or go live.
        </span>
        <span>We don't support rebase tokens.</span>
      </div>
    </div>
  );
}