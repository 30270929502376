// src/component/BottomNavigation.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

import CreateTokenIcon from '../images/CreateCoinIcon.svg';
import BondingCurveIcon from '../images/PumpSaleIcon.svg';
import HomeIcon from '../images/HomeIconBottom.svg';
import FairLaunchIcon from '../images/FairLaunchIconBottom.svg';
import DiamondIcon from '../images/DiamondIconBottom.svg';
import CenterShadow from '../images/CenterShadowBehindHomeIcon.svg';


const BottomNavigation = () => {
  const navigate = useNavigate();

  const handleCreateTokenClick = () => {
    navigate("/token");
  };

  const handleCreateLaunchClick = () => {
    navigate("/fairlaunch");
  };

  const handleBondingCurveClick = () => {
    // Add your logic here or disable the icon
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleFavoritesClick = () => {
    navigate("/favorites");
  };

  return (
    <div className="bottom-navigation mobile-bottom-navigation">
      <img src={CreateTokenIcon} alt="Create Token" className="bottom-navigation-icon" onClick={handleCreateTokenClick} />
      <img src={BondingCurveIcon} alt="Bonding Curve" className="bottom-navigation-icon" onClick={handleBondingCurveClick} style={{ filter: 'grayscale(100%)' }} />
      <div className="center-icon-container" onClick={handleHomeClick}>
        <img src={CenterShadow} alt="Center Shadow" className="center-shadow" />
        <img src={HomeIcon} alt="Home" className="bottom-navigation-icon center-icon" />
      </div>
      <img src={FairLaunchIcon} alt="Fair Launch" className="bottom-navigation-icon" onClick={handleCreateLaunchClick} />
      <img src={DiamondIcon} alt="Diamond" className="bottom-navigation-icon" onClick={handleFavoritesClick} />
    </div>
  );
};

export default BottomNavigation;
