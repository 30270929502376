import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";

import Context from "./context/Context";
import Button from "react-bootstrap-button-loader";
import TokenInput from "../../../component/TokenInput";

export default function Step1() {
  const { value, btnNextStep, setValue } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [createFee, setCreateFee] = useState(0.1);
  const currencyList = [{ address: "", symbol: "SOL" }];
  const firstStepSubmit = (e) => {
    if (
      value.tokenName !== "" ||
      value.tokenDecimal !== "" ||
      value.tokenSymbol !== ""
    ) {
      btnNextStep(e);
    } else {
    }
  };

  return (
    <div
      className={`tab-pane ${value.step === 1 ? "active" : ""}`}
      role="tabpanel"
      id="step1"
    >
      <h4 className="text-center">Enter the token address.</h4>
      <div className="row">
        <TokenInput value={value} setValue={setValue} />
        <div className="col-md-12 mt-4 mb-0">
          <label>Currency</label>
          {currencyList.map((currency, key) => {
            return (
              <div className="form-check">
                <input
                  type="radio"
                  style={{ width: "auto" }}
                  className="form-check-input"
                  name="currency"
                  value={currency.address}
                  // onChange={(e) => handleCurrencyChange(e)}
                  // checked={
                  //   value.currencyTSymbol === currency.symbol ? true : false
                  // }
                  checked={true}
                />
                {currency.symbol}
              </div>
            );
          })}
        </div>
      <div className="col-md-12 mt-4 mb-0">
        <label>Fee</label>
        <div className="form-check">
          <input
            type="radio"
            style={{ width: "auto" }}
            className="form-check-input"
            name="fees"
            value="1"
            // onChange={(e) => handleFeesChange(e)}
            checked={value.feesType === "1"}
          />
          <span>{createFee} SOL</span>
          <div className="info-icon-container">
            <i className="fa fa-info-circle" aria-hidden="true"></i>
            <span className="tooltip-text">+ 5% raised from sale.</span>
          </div>
        </div>
      </div>
      </div>
      {value.ispoolExist ? (
        <ul className="list-inline text-center">
          <li>This pool already exists.</li>
        </ul>
      ) : (
        <ul className="list-inline text-center">
          {/* {value.isApprove ? ( */}
            <li>
              <button
                type="button"
                className="btn default-btn next-step"
                onClick={(e) => firstStepSubmit(e)}
              >
                Continue to next step
              </button>
            </li>
          {/* ) : (
            <li>
              <Button
                type="button"
                variant="none"
                className="btn default-btn next-step"
                onClick={(e) => handleApprove(e)}
                loading={loading}
              >
                Approve
              </Button>
            </li>
          )} */}
        </ul>
      )}
    </div>
  );
}

