import React, { createContext, useContext, useState, useEffect } from 'react';

const FavoritesContext = createContext();

export const useFavorites = () => useContext(FavoritesContext);

export const FavoritesProvider = ({ children }) => {
    const [favoriteProjects, setFavoriteProjects] = useState(() => {
        const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
        return favorites;
    });

    useEffect(() => {
        localStorage.setItem('favorites', JSON.stringify(favoriteProjects));
    }, [favoriteProjects]);

    const toggleFavorite = (poolAddress) => {
        setFavoriteProjects((prevFavorites) => {
            let updatedFavorites;
            if (prevFavorites.includes(poolAddress)) {
                updatedFavorites = prevFavorites.filter(address => address !== poolAddress);
            } else {
                updatedFavorites = [...prevFavorites, poolAddress];
            }
            localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
            return updatedFavorites;
        });
    };

    return (
        <FavoritesContext.Provider value={{ favoriteProjects, toggleFavorite }}>
            {children}
        </FavoritesContext.Provider>
    );
};
