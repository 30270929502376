import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";

import dateFormat from "dateformat";
import Button from "react-bootstrap-button-loader";
import { formatPrice } from "../../../../hooks/helper";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import { AiFillEdit } from 'react-icons/ai';
import iconImage from "../../../../images/icon.png";
import templateImg from '../../../../images/template.jpg';
import bannerTemplate from "../../../../images/template_banner.png";
import { usePoolStats, useAccountStats } from "../../SaleList/helper/useStats";
import { ADMIN_PUBKEY, WSOL_MINT, CURRENT_RAYDIUM_PROGRAM, trimAddress, RAYDIUM_CP_SWAP_PROGRAM_ID, RAYDIUM_CP_SWAP_POOL_FEE, LOCK_PROGRAM_ID, MODIFY_COMPUTE_UNITS, ADD_PRIORITY_FEE } from "../../../../hooks/constant";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import HashLoader from "react-spinners/HashLoader";
import { BN, AnchorProvider, setProvider, Program } from "@coral-xyz/anchor";
import IDL from "../../../../json/idl.json";
import FAIR_SALE_LOCK_IDL from "../../../../json/fair_sale_lock.json";
import { FAIR_SALE_PROGRAM_ID, GLOBAL_INFO_PUB_KEY } from "../../../../hooks/constant";
import * as anchor from "@coral-xyz/anchor";
import { useNavigate } from "react-router-dom";
import discordIcon from "../../../../images/discord.svg";
import twitterIcon from "../../../../images/x.svg";
import telegramIcon from "../../../../images/telegram.svg";
import websiteIcon from "../../../../images/website.svg";

import {
  getAmmConfigAddress,
  u16ToBytes,
  getAuthAddress,
  getPoolAddress,
  getPoolLpMintAddress,
  getPoolVaultAddress,
} from '../../../../hooks/raydiumCpSwapHelper';

import RAYDIUM_CP_SWAP_IDL from '../../../../json/raydium_sp_swap_idl.json';
import { PoolUtil } from "@orca-so/whirlpools-sdk";

import {
  PublicKey,
  Transaction,
  Keypair,
  SYSVAR_RENT_PUBKEY,
  SystemProgram
} from "@solana/web3.js";
import {
  TOKEN_PROGRAM_ID,ASSOCIATED_TOKEN_PROGRAM_ID
} from "@solana/spl-token";

export default function ProjectDetails() {
  let { address } = useParams();
  const [updater, setUpdater] = useState({
    loading: true,
  });
  const [openbookMarketId, setOpenbookMarketId] = useState();
  const navigate = useNavigate();
  const { publicKey, sendTransaction } = useWallet();
  const { connection } = useConnection();
  const [activeTab, setActiveTab] = useState('Sale Details'); // State for managing active tab
  let stats = usePoolStats(updater, address);
  const accStats = useAccountStats(updater, stats.pool);
  const [currentTime] = useState(Math.floor(new Date().getTime()));
  const startTime = new Date(stats.pool.startTime);
  const endTime = new Date(stats.pool.endTime);
  const liquidityUnlockTime = startTime + stats.liquidityLockdays * 24 * 60 * 60 * 1000;
  const [btndisabled, setBtndisabled] = useState(false);
  const [error_msg, setError_msg] = useState("");
  const [loading, setLoading] = useState(false);
  const [finalLoading, setFinalLoading] = useState(false);
  const [wcLoading, setWcLoading] = useState(false);
  const [ctLoading, setCtLoading] = useState(false);
  const [locklLoading, setLocklLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [refcopy1, setRefcopy1] = useState(false);
  const [refcopy2, setRefcopy2] = useState(false);
  const [amount, setAmount] = useState(0);
  const [editmodalShow, setEditmodalShow] = useState(false);
  const [contributeLoading, setContributeLoading] = useState(false);
  const [error, setError] = useState({
    logourl: "",
    bannerurl: "",
    website: "",
    facebook: "",
    twitter: "",
    github: "",
    telegram: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtube: "",
    brief: "",
    blockstart: "",
  });

  const [editSocial, setEditSocial] = useState({
    logourl: "",
    bannerurl: "",
    website: "",
    blockstart: "",
    facebook: "",
    twitter: "",
    github: "",
    telegram: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtube: "",
    brief: "",
  });
  const [imgageURL, setImageURL] = React.useState("");
  const [bannerURL, setBannerURL] = React.useState("");
  const [tokenAddr, setTokenAddr] = useState('');
  const [poolAddr, setPoolAddr] = useState('');
  useEffect(() => {
    function getDetails() {
      const object = {
        logourl: stats.pool.logourl,
        bannerurl: stats.pool.bannerurl,
        website: stats.pool.website,
        blockstar: stats.pool.blockstar,
        facebook: stats.pool.facebook,
        twitter: stats.pool.twitter,
        github: stats.pool.github,
        telegram: stats.pool.telegram,
        instagram: stats.pool.instagram,
        discord: stats.pool.discord,
        reddit: stats.pool.reddit,
        youtube: stats.pool.youtube,
        brief: stats.pool.brief,
      };

      setEditSocial(object);
      setImageURL(object.logourl);
      setBannerURL(object.bannerurl);
      try {
        setPoolAddr(window.innerWidth < 768 ? trimAddress(stats.pool.poolAddress?.toString()) : stats.pool.poolAddress?.toString());
        setTokenAddr(window.innerWidth < 768 ? trimAddress(stats.pool.token?.toString()) : stats.pool.token?.toString());
      } catch (e) {
        console.log("error on responsive", e);
      }
    }
    if (stats) {
      getDetails();
    }
    const handleResize = () => {
      try {
        setPoolAddr(window.innerWidth < 768 ? trimAddress(stats.pool.poolAddress?.toString()) : stats.pool.poolAddress?.toString());
        setTokenAddr(window.innerWidth < 768 ? trimAddress(stats.pool.token?.toString()) : stats.pool.token?.toString());
      } catch {
        console.log("error on resize");
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [stats]);

  const setimage = () => {
    setImageURL(templateImg);
  }
  const setBannerImage = () => {
    setBannerURL(bannerTemplate)
  }
  
  const checkValidation = (input, inputValue) => {
    let terror = 0;
    let message = "";
    var reg;
    switch (input) {
      case "facebook":
      case "twitter":
      case "github":
      case "telegram":
      case "instagram":
      case "discord":
      case "reddit":
      case "youtube":
      case "blockstart":
        reg = new RegExp(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
        );
        if (inputValue !== "" && !reg.test(inputValue)) {
          terror += 1;
          message = "Please Enter Valid url!";
        } else {
          message = "";
        }
        break;

      case "logourl":
      case "bannerurl":
      case "website":
        reg = new RegExp(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
        );
        if (!reg.test(inputValue)) {
          terror += 1;
          message = "Please Enter Valid url!";
        } else {
          message = "";
        }
        break;
      default:
        terror += 0;
        break;
    }

    if (terror > 0) {
      setError({ ...error, [input]: message });
      return false;
    } else {
      setError({ ...error, [input]: "" });
      return true;
    }
  };
  const checkAllValidation = () => {
    let terror = 0;
    var reg;
    Object.keys(editSocial).map((key) => {
      switch (key) {
        case "facebook":
        case "twitter":
        case "github":
        case "telegram":
        case "instagram":
        case "discord":
        case "reddit":
        case "youtube":
        case "blockstart":
          reg = new RegExp(
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
          );
          if (editSocial[key] !== "" && !reg.test(editSocial[key])) {
            terror += 1;
          }

          break;

        case "logourl":
        case "bannerurl":
        case "website":
          reg = new RegExp(
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
          );
          if (!reg.test(editSocial[key])) {
            terror += 1;
          }

          break;
        default:
          terror += 0;
          break;
      }
      return true;
    });

    if (terror > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    checkValidation(e.target.name, e.target.value);
    setEditSocial({ ...editSocial, [e.target.name]: e.target.value });
  };

  // Define the missing functions
  const countdownrender = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className='timer timer_1'>
          <ul>
            <li>
              <pre>{zeroPad(0, 2)} D : </pre>
            </li>
            <li>
              <pre>{zeroPad(0, 2)} H : </pre>
            </li>
            <li>
              <pre>{zeroPad(0, 2)} M : </pre>
            </li>
            <li>
              <pre>{zeroPad(0, 2)} S </pre>
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className='timer timer_1'>
          <ul>
            <li>
              <pre>{zeroPad(days, 2)} D : </pre>
            </li>
            <li>
              <pre>{zeroPad(hours, 2)} H : </pre>
            </li>
            <li>
              <pre>{zeroPad(minutes, 2)} M : </pre>
            </li>
            <li>
              <pre>{zeroPad(seconds, 2)} S : </pre>
            </li>
          </ul>
        </div>
      );
    }
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
    setBtndisabled(true);

    if (isNaN(e.target.value)) {
      setError_msg("please enter valid amount");
      setBtndisabled(true);
    } else if (parseFloat(e.target.value) === 0 || e.target.value === "") {
      setError_msg("amount must be greater than zero");
      setBtndisabled(true);
    } else {
      setError_msg("");
      setBtndisabled(false);
    }
    return;
  };

  const handleMaxAmount = (e) => {
    e.preventDefault();
    let maxAmount = accStats.balance / 10 ** 9;
    setAmount(maxAmount.toFixed(3));
  };

  const handleSubmitContribution = async (e) => {
    e.preventDefault();
    setContributeLoading(true);
    let governance = new PublicKey(stats.pool?.governance);
    try {
      if (amount > 0) {
        if (publicKey) {
          if (parseFloat(accStats.balance / (10 ** 9)) >= parseFloat(amount)) {
            const [fair_sale_pda] = PublicKey.findProgramAddressSync(
              [anchor.utils.bytes.utf8.encode("fair-sale"), stats.pool.token.toBuffer(), governance.toBuffer()], FAIR_SALE_PROGRAM_ID
            );
            const provider = new AnchorProvider(connection, Keypair.generate(), {});
            setProvider(provider);
            const programId = FAIR_SALE_PROGRAM_ID;
            const program = new Program(IDL, programId);
            const instruction = await program.methods.contribute(
              new anchor.BN(Number(amount) * 10 ** 9))
              .accounts({
                fairSale: fair_sale_pda, //pda
                contributor: publicKey,
                governance: governance,
                splMint: stats.pool?.token,
                tokenProgram: TOKEN_PROGRAM_ID,
                rent: SYSVAR_RENT_PUBKEY
              }).instruction();
            const transaction = new Transaction();
            transaction.add(MODIFY_COMPUTE_UNITS);
            transaction.add(ADD_PRIORITY_FEE);
            transaction.add(instruction);

            const tx = await sendTransaction(transaction, connection, { skipPreflight: true })
              .then().catch((e) => {
                console.log("Error on contribution", e);
                toast.error("Contribution failed!");
                setContributeLoading(false);
              });

            if (tx) {
              const result = await connection.confirmTransaction({
                signature: tx
              }, 'confirmed');
              if (result.value.err == null) {
                toast.success("Contributed!");
                setContributeLoading(false);
                navigate('/');
              } else if (result.value.err?.InstructionError[1].Custom === 6024) {
                toast.error("Start time didn't get reached!");
                setContributeLoading(false);
              } else {
                toast.error("Contribution failed!");
                setContributeLoading(false);
              }
            }
          } else {
            toast.error("You don't have enough balance!");
            setContributeLoading(false);
          }
        } else {
          toast.error("Please Connect Wallet!");
          setContributeLoading(false);
        }
      } else {
        toast.error("Please Enter Valid Amount!");
        setContributeLoading(false);
      }
    } catch (e) {
      console.log("error on contribution", e);
      toast.error("Something went wrong while sending contribution!");
      setContributeLoading(false);
    }
  };

  const handleClaimToken = async (e) => {
    setCtLoading(true);
    try {
      if (publicKey) {
        const provider = new AnchorProvider(connection, Keypair.generate(), {});
        setProvider(provider);
        const programId = FAIR_SALE_PROGRAM_ID;
        const program = new Program(IDL, programId);
        const fair_sale_pda = stats.pool?.poolAddress;
        const [fair_sale_spl_ata] = PublicKey.findProgramAddressSync(
          [
            stats.pool.poolAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), stats.pool?.token.toBuffer()
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const [contributor_spl_ata] = PublicKey.findProgramAddressSync(
          [
            publicKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), stats.pool?.token.toBuffer()
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const instruction = await program.methods.claim()
          .accounts({
            fairSale: fair_sale_pda, //pda
            contributor: publicKey,
            fairSaleSplAta: fair_sale_spl_ata,
            contributorSplAta: contributor_spl_ata,
            splMint: stats.pool?.token,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            rent: SYSVAR_RENT_PUBKEY
          }).instruction();
        const transaction = new Transaction();
        transaction.add(MODIFY_COMPUTE_UNITS);
        transaction.add(ADD_PRIORITY_FEE);
        transaction.add(instruction);

        const tx = await sendTransaction(transaction, connection, { skipPreflight: true })
          .then().catch((e) => {
            console.log("error on claim", e);
            toast.error("Claim Failed!");
            setCtLoading(false);
          });

        if (tx) {
          const result = await connection.confirmTransaction({
            signature: tx
          }, 'confirmed');
          if (result.value.err == null) {
            toast.success("Claimed!");
            setCtLoading(false);
            navigate('/');
          } else {
            toast.error("Claim Failed!");
            setCtLoading(false);
          }
        }
      } else {
        toast.error("Please Connect Wallet!");
        setCtLoading(false);
      }
    } catch (e) {
      toast.error("Claim Failed!");
      setCtLoading(false);
      console.log("error on claim", e)
    }
  };

  const handleWithdrawContribution = async (e) => {
    e.preventDefault();
    setWcLoading(true);
    try {
      if (publicKey) {
        const provider = new AnchorProvider(connection, Keypair.generate(), {});
        setProvider(provider);
        const programId = FAIR_SALE_PROGRAM_ID;
        const program = new Program(IDL, programId);
        const fair_sale_pda = stats.pool?.poolAddress;
        const instruction = await program.methods.cancelContribute()
          .accounts({
            fairSale: fair_sale_pda, //pda
            contributor: publicKey,
            fairsaleGlobalInfo: GLOBAL_INFO_PUB_KEY,
            splMint: stats.pool?.token,
          }).instruction();
        const transaction = new Transaction();
        transaction.add(MODIFY_COMPUTE_UNITS);
        transaction.add(ADD_PRIORITY_FEE);
        transaction.add(instruction);

        const tx = await sendTransaction(transaction, connection, { skipPreflight: true })
          .then().catch((e) => {
            console.log("wallet error on contribution cancel", e);
            toast.error("Cancellation failed!");
            setWcLoading(false);
          });

        if (tx) {
          const result = await connection.confirmTransaction({
            signature: tx
          }, 'confirmed');
          if (result.value.err == null) {
            toast.success("Canceled!");
            setWcLoading(false);
            navigate('/');
          } else if (result.value.err?.InstructionError[1].Custom === 6005) {
            toast.error("End time didn't get reached!");
            setWcLoading(false);
          } else if (result.value.err?.InstructionError[1].Custom === 6008) {
            toast.error("Already softcap reached!");
            setWcLoading(false);
          } else {
            toast.error("Cancellation failed!");
            setWcLoading(false);
          }
        }
      } else {
        toast.error("Please Connect Wallet!");
        setWcLoading(false);
      }
    } catch (e) {
      setWcLoading(false);
      console.log("error on contribution cancel", e);
      toast.error("Cancellation failed!");
    }
  };

  const handleFinalize = async (e) => {
    e.preventDefault();
    setFinalLoading(true);
    try {
      if (publicKey) {
        let governance = new PublicKey(stats.pool?.governance);
        const [fair_sale_wsol_ata] = PublicKey.findProgramAddressSync(
          [
            stats.pool.poolAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), WSOL_MINT.toBuffer()
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const [governance_ata] = PublicKey.findProgramAddressSync(
          [governance.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), stats.pool?.token.toBuffer()],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const [governance_wsol_ata] = PublicKey.findProgramAddressSync(
          [governance.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), WSOL_MINT.toBuffer()],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const [fair_sale_spl_ata] = PublicKey.findProgramAddressSync(
          [
            stats.pool.poolAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), stats.pool?.token.toBuffer()
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const provider = new AnchorProvider(connection, Keypair.generate(), {});
        setProvider(provider);
        const programId = FAIR_SALE_PROGRAM_ID;
        const program = new Program(IDL, programId);
        const instruction_1 = await program.methods
          .finalizePresaleSendSol()
          .accounts({
            fairSale: stats.pool.poolAddress,
            governance: publicKey,
            admin: ADMIN_PUBKEY,
            fairSaleWsolAta: fair_sale_wsol_ata,
            wsolMint: WSOL_MINT,
            fairSaleSplAta: fair_sale_spl_ata,
            fairsaleGlobalInfo: GLOBAL_INFO_PUB_KEY,
            governanceSplAta: governance_ata,
            splMint: stats.pool?.token,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            tokenProgram: TOKEN_PROGRAM_ID,
          })
          .instruction();


        const instruction_2 = await program.methods
          .finalizePresaleSendToWsol()
          .accounts({
            fairSale: stats.pool.poolAddress,
            governance: publicKey,
            admin: ADMIN_PUBKEY,
            fairSaleWsolAta: fair_sale_wsol_ata,
            wsolMint: WSOL_MINT,
            splMint: stats.pool?.token,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            tokenProgram: TOKEN_PROGRAM_ID,
          })
          .instruction();

        const instruction_3 = await program.methods
          .withdrawTokenToOwner()
          .accounts({
            fairSale: stats.pool.poolAddress,
            governance: publicKey,
            governanceSplAta: governance_ata,
            fairSaleSplAta: fair_sale_spl_ata,
            splMint: stats.pool?.token,
            wsolMint: WSOL_MINT,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            rent: SYSVAR_RENT_PUBKEY
          })
          .instruction();

        const instruction_4 = await program.methods
          .withdrawWsolToOwner()
          .accounts({
            fairSale: stats.pool.poolAddress,
            governance: publicKey,
            governanceWsolAta: governance_wsol_ata,
            fairSaleWsolAta: fair_sale_wsol_ata,
            splMint: stats.pool?.token,
            wsolMint: WSOL_MINT,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            rent: SYSVAR_RENT_PUBKEY
          })
          .instruction();
        //check binery size of token mint. determine token order
        const [mint_a, mint_b] = PoolUtil.orderMints(WSOL_MINT, stats.pool.token);
        let orderStatus;
        if (mint_a.toString() === WSOL_MINT.toString()) {
          orderStatus = true;
        } else {
          orderStatus = false;
        }

        const config_index = 0;
        let raydium_cp_swap_program = new Program(RAYDIUM_CP_SWAP_IDL, RAYDIUM_CP_SWAP_PROGRAM_ID);
        let [configAddress, _] = await getAmmConfigAddress(
          config_index,
          raydium_cp_swap_program.programId
        );

        const [auth] = await getAuthAddress(raydium_cp_swap_program.programId);
        const [poolAddress] = await getPoolAddress(
          configAddress,
          orderStatus ? WSOL_MINT : stats.pool.token,
          orderStatus ? stats.pool.token : WSOL_MINT,
          raydium_cp_swap_program.programId
        );
        const [lpMintAddress] = await getPoolLpMintAddress(
          poolAddress,
          raydium_cp_swap_program.programId
        );
        const [vault0] = await getPoolVaultAddress(
          poolAddress,
          orderStatus ? WSOL_MINT : stats.pool.token,
          raydium_cp_swap_program.programId
        );
        const [vault1] = await getPoolVaultAddress(
          poolAddress,
          orderStatus ? stats.pool.token : WSOL_MINT,
          raydium_cp_swap_program.programId
        );

        const [creatorLpTokenAddress] = await PublicKey.findProgramAddressSync(
          [
            publicKey.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            lpMintAddress.toBuffer(),
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );

        const [fair_sale_lp_ata] = await PublicKey.findProgramAddressSync(
          [
            stats.pool.poolAddress.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            lpMintAddress.toBuffer(),
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );


        const [creatorToken0] = await PublicKey.findProgramAddressSync([publicKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), orderStatus ? WSOL_MINT.toBuffer() : stats.pool.token.toBuffer()], ASSOCIATED_TOKEN_PROGRAM_ID);
        const [creatorToken1] = await PublicKey.findProgramAddressSync([publicKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), orderStatus ? stats.pool.token.toBuffer() : WSOL_MINT.toBuffer()], ASSOCIATED_TOKEN_PROGRAM_ID);


        const observation_see = "observation";
        const [observationState] = PublicKey.findProgramAddressSync(
          [Buffer.from("observation"), poolAddress.toBuffer()],
          raydium_cp_swap_program.programId
        );

        const createPoolFee = new PublicKey(RAYDIUM_CP_SWAP_POOL_FEE);

        const instruction_5 = await program.methods
          .raydiumV5Initialize()
          .accounts({
            raydiumV5Program: raydium_cp_swap_program.programId,
            fairSale: stats.pool.poolAddress,
            creator: publicKey,
            ammConfig: configAddress,
            authority: auth,
            poolState: poolAddress,
            token0Mint: WSOL_MINT,
            token1Mint: stats.pool.token,
            lpMint: lpMintAddress,
            creatorToken0,
            creatorToken1,
            creatorLpToken: creatorLpTokenAddress,
            token0Vault: vault0,
            token1Vault: vault1,
            createPoolFee: createPoolFee,
            observationState,
            tokenProgram: TOKEN_PROGRAM_ID,
            token0Program: TOKEN_PROGRAM_ID,
            token1Program: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY,
          })
          .instruction();

        const [lock_pda] = PublicKey.findProgramAddressSync(
          [Buffer.from("fair-sale-lock"), stats.pool.token.toBuffer(), publicKey.toBuffer()],
          LOCK_PROGRAM_ID
        );
        const [lock_pda_spl_ata] = PublicKey.findProgramAddressSync(
          [lock_pda.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), lpMintAddress.toBuffer()],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const send_position_mint_to_lock_for_raydium = await program.methods
          .sendPositionMintToLockForRaydium(
            "raydium v5"
          )
          .accounts({
            fairSale: stats.pool.poolAddress,
            governance: publicKey,
            positionTokenAccount: creatorLpTokenAddress,
            lockProgram: LOCK_PROGRAM_ID,
            lockPda: lock_pda,
            splMint: stats.pool.token,
            wsolMint: WSOL_MINT,
            positionMint: lpMintAddress,
            lockPdaSplAta: lock_pda_spl_ata,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY,
          })
          .instruction();
        const transaction = new Transaction();
        transaction.add(MODIFY_COMPUTE_UNITS);
        transaction.add(ADD_PRIORITY_FEE);
        transaction.add(instruction_1);
        transaction.add(instruction_2);
        transaction.add(instruction_3);
        transaction.add(instruction_4);
        transaction.add(instruction_5);
        transaction.add(send_position_mint_to_lock_for_raydium);

        const tx = await sendTransaction(transaction, connection, { skipPreflight: true })
          .then().catch((e) => {
            console.log("Error on finalizing!", e);
            setFinalLoading(false);
          });

        if (tx) {
          const result = await connection.confirmTransaction({
            signature: tx
          }, 'confirmed');
          if (result.value.err == null) {
            toast.success("Finalized!");
            setFinalLoading(false);
            navigate('/');
          } else {
            toast.error("Finalize Failed!");
            setFinalLoading(false);
          }
        }
      } else {
        toast.error("Please Connect to wallet!");
        setFinalLoading(false);
      }
    } catch (e) {
      console.log("error on finalize", e);
      setFinalLoading(false);
    }
  };

  const handleWithdrawLiquidity = async (e) => {
    e.preventDefault();
    setLocklLoading(true);
    try {
      if (publicKey) {
        const provider = new AnchorProvider(connection, Keypair.generate(), {});
        setProvider(provider);
        const programId = LOCK_PROGRAM_ID;
        const program = new Program(FAIR_SALE_LOCK_IDL, programId);
        const [lock_pda] = PublicKey.findProgramAddressSync(
          [Buffer.from("fair-sale-lock"), stats.pool.token.toBuffer(), publicKey.toBuffer()],
          LOCK_PROGRAM_ID
        );
        const config_index = 0;
        let raydium_cp_swap_program = new Program(RAYDIUM_CP_SWAP_IDL, RAYDIUM_CP_SWAP_PROGRAM_ID);
        let [configAddress, _] = await getAmmConfigAddress(
          config_index,
          raydium_cp_swap_program.programId
        );

        const [mint_a, mint_b] = PoolUtil.orderMints(WSOL_MINT, stats.pool.token);
        let orderStatus;
        if (mint_a.toString() === WSOL_MINT.toString()) {
          orderStatus = true;
        } else {
          orderStatus = false;
        }
        const [poolAddress] = await getPoolAddress(
          configAddress,
          orderStatus ? WSOL_MINT : stats.pool.token,
          orderStatus ? stats.pool.token : WSOL_MINT,
          raydium_cp_swap_program.programId
        );
        const [lpMintAddress] = await getPoolLpMintAddress(
          poolAddress,
          raydium_cp_swap_program.programId
        );
        const [lock_pda_spl_ata] = PublicKey.findProgramAddressSync(
          [lock_pda.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), lpMintAddress.toBuffer()],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const [creatorLpTokenAddress] = await PublicKey.findProgramAddressSync(
          [
            publicKey.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            lpMintAddress.toBuffer(),
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const instruction = await program.methods.unlockLp()
          .accounts({
            lockPda: lock_pda, //pda
            authority: publicKey,
            splMint: stats.pool?.token,
            positionMint: lpMintAddress,
            lockPdaSplAta: lock_pda_spl_ata,
            authoritySplAta: creatorLpTokenAddress,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY
          }).instruction();
        const transaction = new Transaction();
        transaction.add(MODIFY_COMPUTE_UNITS);
        transaction.add(ADD_PRIORITY_FEE);
        transaction.add(instruction);

        const tx = await sendTransaction(transaction, connection, { skipPreflight: true })
          .then().catch((e) => {
            console.log("error on withdraw liquidity", e);
            setLocklLoading(false);
            toast.error("Withdraw failed!");
          });

        if (tx) {
          const result = await connection.confirmTransaction({
            signature: tx
          }, 'confirmed');
          if (result.value.err == null) {
            toast.success("Withdraw Success!");
            setLocklLoading(false);
            navigate('/');
          } else {
            toast.error("Withdraw Failed!");
            setLocklLoading(false);
          }
        }
      } else {
        toast.error("Please Connect Wallet!");
        setLocklLoading(false);
      }
    } catch (e) {
      setLocklLoading(false);
      console.log("error on withdraw liquidity", e);
    }
  };

  const handleCancel = async (e) => {
    e.preventDefault();
    setLocklLoading(true);
    try {
      if (publicKey) {
        let governance = new PublicKey(stats.pool?.governance);
        const provider = new AnchorProvider(connection, Keypair.generate(), {});
        setProvider(provider);
        const programId = FAIR_SALE_PROGRAM_ID;
        const program = new Program(IDL, programId);
        const fair_sale_pda = stats.pool?.poolAddress;
        const [governance_ata] = PublicKey.findProgramAddressSync(
          [governance.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), stats.pool?.token.toBuffer()],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const [fair_sale_spl_ata] = PublicKey.findProgramAddressSync(
          [
            stats.pool.poolAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), stats.pool?.token.toBuffer()
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        const instruction = await program.methods.cancelFairSale()
          .accounts({
            fairSale: fair_sale_pda, //pda
            governance: publicKey,
            fairsaleGlobalInfo: GLOBAL_INFO_PUB_KEY,
            splMint: stats.pool?.token,
            governanceSplAta: governance_ata,
            fairSaleSplAta: fair_sale_spl_ata,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            rent: SYSVAR_RENT_PUBKEY
          }).instruction();
        const delinstruction = await program.methods.closeFairSaleAccount()
          .accounts({
            fairSale: fair_sale_pda,
            governance: publicKey
          }).instruction();
        const transaction = new Transaction();
        transaction.add(MODIFY_COMPUTE_UNITS);
        transaction.add(ADD_PRIORITY_FEE);
        transaction.add(instruction);
        transaction.add(delinstruction);

        const tx = await sendTransaction(transaction, connection, { skipPreflight: true })
          .then().catch((e) => {
            console.log("error on cancel", e);
            toast.error("Error on cancellation!");
            setLocklLoading(false);
          });

        if (tx) {
          const result = await connection.confirmTransaction({
            signature: tx
          }, 'confirmed');
          if (result.value.err == null) {
            toast.success("Canceled!");
            setLocklLoading(false);
            navigate('/');
          } else {
            toast.error("Cancellation Failed!");
            setLocklLoading(false);
          }
        }
      }
    } catch (e) {
      console.log("error on cancel", e);
      setLocklLoading(false);
    }
  };

  const handleEditSubmit = async (e) => {
    let check = checkAllValidation();
    if (check) {
      e.preventDefault();
      setEditLoading(true);
      try {
        if (publicKey) {
          let detailsset = `${editSocial.logourl}$#$${editSocial.bannerurl}$#$${editSocial.website}$#$${editSocial.blockstart}$#$${editSocial.facebook}$#$${editSocial.twitter}$#$${editSocial.github}$#$${editSocial.telegram}$#$${editSocial.instagram}$#$${editSocial.discord}$#$${editSocial.reddit}$#$${editSocial.youtube}$#$${editSocial.brief}`;
          const [fair_sale_pda] = PublicKey.findProgramAddressSync(
            [anchor.utils.bytes.utf8.encode("fair-sale"), stats.pool.token.toBuffer(), publicKey.toBuffer()], FAIR_SALE_PROGRAM_ID
          );
          const provider = new AnchorProvider(connection, Keypair.generate(), {});
          setProvider(provider);
          const programId = FAIR_SALE_PROGRAM_ID;
          const program = new Program(IDL, programId);
          const instruction = await program.methods.updatePoolDetails(
            detailsset)
            .accounts({
              fairSale: fair_sale_pda, //pda
              governance: publicKey,
              splMint: stats.pool?.token,
            }).instruction();
          const transaction = new Transaction();
          transaction.add(MODIFY_COMPUTE_UNITS);
          transaction.add(ADD_PRIORITY_FEE);
          transaction.add(instruction);

          const tx = await sendTransaction(transaction, connection, { skipPreflight: true })
            .then().catch((e) => {
              console.log("Error on Edit", e);
              setEditLoading(false);
            });
          if (tx) {
            const result = await connection.confirmTransaction({
              signature: tx
            }, 'confirmed');
            if (result.value.err == null) {
              toast.success("Updated!");
              setEditLoading(false);
              navigate('/');
            } else {
              toast.error("Update Failed!");
              setEditLoading(false);
            }
          }
        } else {
          toast.error("Please Connect to wallet!");
          setEditLoading(false);
        }
      } catch (err) {
        toast.error(err.reason ? err.reason : err.message);
        setEditLoading(false);
      }
    }
    else {
      toast.error(
        "Required all field or Enter wrong value  ! please check again"
      );
    }
  };

  return (
    <React.Fragment>
      <div className='detail-page container mt-3'>
        <section className='item-details-area'>
          {stats.loading ? (
            <div className="col-md-12">
              <HashLoader
                size="100"
                color="#fff"
                cssOverride={{
                  left: "50%",
                  textAlign: "center",
                  top: "50%",
                }}
              />
            </div>
          ) : (




            
            <div className='row'>
              <div className='col-12 col-md-8'>
                <div className='card project-card no-hover py-4 px-2'>
                  <div className='row'>
                    <div className='col-12 col-md-2 text-center'>
                      <img
                        className='card-img-top avatar-max-lg mt-1 '
                        width='100%'
                        height='auto'
                        borderradius= '50%'
                        src={imgageURL}
                        onError={()=>setimage()}
                        alt='iconimage12'
                        style={{borderRadius: '50%', objectFit: 'cover', minWidth: '50px'}}
                      />
                    </div>
                    <div className='col-12 col-md-10'>
                      <div className='row align-items-center justify-content-md-start justify-content-center'>
                        <h4 className='mt-1 mb-2 text-center text-md-left'>
                          {stats.pool.name} {/* Fairlaunch */}
                        </h4>
                      </div>
                      <div className='social-share d-flex justify-content-center justify-content-md-start'>
                        <ul className='d-flex list-unstyled'>
                          {stats.pool.twitter && (
                            <li>
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={stats.pool.twitter}
                              >
                                <img src={twitterIcon} alt="Twitter" style={{ width: '24px', height: '24px', color: 'grey' }} />
                              </a>
                            </li>
                          )}
                          {stats.pool.telegram && (
                            <li>
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={stats.pool.telegram}
                              >
                                <img src={telegramIcon} alt="Telegram" style={{ width: '24px', height: '24px', color: 'grey' }} />
                              </a>
                            </li>
                          )}
                          {stats.pool.website && (
                            <li>
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={stats.pool.website}
                              >
                                <img src={websiteIcon} alt="Website" style={{ width: '24px', height: '24px', color: 'grey' }} />
                              </a>
                            </li>
                          )}
                          {stats.pool.discord && (
                            <li>
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={stats.pool.discord}
                              >
                                <img src={discordIcon} alt="Discord" style={{ width: '24px', height: '24px', color: 'grey' }} />
                              </a>
                            </li>
                          )}
                          {stats.pool.facebook && (
                            <li>
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={stats.pool.facebook}
                              >
                                <i className='fab fa-facebook' style={{ color: 'grey' }}></i>
                              </a>
                            </li>
                          )}
                          {stats.pool.github && (
                            <li>
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={stats.pool.github}
                              >
                                <i className='fab fa-github' style={{ color: 'grey' }}></i>
                              </a>
                            </li>
                          )}
                          {stats.pool.instagram && (
                            <li>
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={stats.pool.instagram}
                              >
                                <i className='fab fa-instagram' style={{ color: 'grey' }}></i>
                              </a>
                            </li>
                          )}
                          {stats.pool.reddit && (
                            <li>
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={stats.pool.reddit}
                              >
                                <i className='fab fa-reddit' style={{ color: 'grey' }}></i>
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>

                      <p className='text-center text-md-left'>
                        {stats.pool.brief}
                      </p>
                    </div>
                  </div>

{/* Mobile Header Buttons */}
<div
  className="header-buttons-mobile d-flex justify-content-center mt-4 flex-nowrap d-md-none"
  style={{
    gap: '0px',
    flexWrap: 'nowrap',
    width: '100%',
    padding: '5px 5px',
    marginBottom: '0px',
  }}
>
  <div
    className={`header-button ${activeTab === 'Sale Details' ? 'active' : ''}`}
    onClick={() => setActiveTab('Sale Details')}
    style={{
      background: activeTab === 'Sale Details' ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : 'rgba(22, 26, 66, 0.70)',
      color: activeTab === 'Sale Details' ? '#F1F8FD' : '#7B91B0',
      borderTopLeftRadius: '4px', // Top left corner
      borderTopRightRadius: '0px', // Top right corner
      borderBottomLeftRadius: '4px', // Bottom left corner
      borderBottomRightRadius: '0px', // Bottom right corner
      border: '1px #3461FF solid',
      padding: '2px 4px',
      cursor: 'pointer',
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '30px',
      fontFamily: 'Quicksand, sans-serif',
      minWidth: '70px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      flex: '1',
    }}
  >
    Sale Info
  </div>
  <div
    className={`header-button ${activeTab === 'Tokenomics' ? 'active' : ''}`}
    onClick={() => setActiveTab('Tokenomics')}
    style={{
      background: activeTab === 'Tokenomics' ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : 'rgba(22, 26, 66, 0.70)',
      color: activeTab === 'Tokenomics' ? '#F1F8FD' : '#7B91B0',
      borderTopLeftRadius: '0px', // Top left corner
      borderTopRightRadius: '0px', // Top right corner
      borderBottomLeftRadius: '0px', // Bottom left corner
      borderBottomRightRadius: '0px', // Bottom right corner
      border: '1px #3461FF solid',
      padding: '2px 4px',
      cursor: 'pointer',
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '30px',
      fontFamily: 'Quicksand, sans-serif',
      minWidth: '70px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      flex: '1',
    }}
  >
    Tokenomics
  </div>
  <div
    className={`header-button ${activeTab === 'Description' ? 'active' : ''}`}
    onClick={() => setActiveTab('Description')}
    style={{
      background: activeTab === 'Description' ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : 'rgba(22, 26, 66, 0.70)',
      color: activeTab === 'Description' ? '#F1F8FD' : '#7B91B0',
      borderTopLeftRadius: '0px', // Top left corner
      borderTopRightRadius: '4px', // Top right corner
      borderBottomLeftRadius: '0px', // Bottom left corner
      borderBottomRightRadius: '4px', // Bottom right corner
      border: '1px #3461FF solid',
      padding: '2px 4px',
      cursor: 'pointer',
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '30px',
      fontFamily: 'Quicksand, sans-serif',
      minWidth: '70px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      flex: '1',
    }}
  >
    Project
  </div>
</div>

{/* Desktop Header Buttons */}
<div
  className="header-buttons-desktop d-none d-md-flex justify-content-center mt-4"
  style={{
    gap: '0px',
    width: '100%',
    marginBottom: '0px',
  }}
>
  <div
    className={`header-button ${activeTab === 'Sale Details' ? 'active' : ''}`}
    onClick={() => setActiveTab('Sale Details')}
    style={{
      background: activeTab === 'Sale Details' ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : 'rgba(22, 26, 66, 0.70)',
      color: activeTab === 'Sale Details' ? '#F1F8FD' : '#7B91B0',
      borderTopLeftRadius: '8px', // Top left corner
      borderTopRightRadius: '0px', // Top right corner
      borderBottomLeftRadius: '8px', // Bottom left corner
      borderBottomRightRadius: '0px', // Bottom right corner
      border: '2.59px #3461FF solid',
      padding: '10px 20px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '20px',
      fontFamily: 'Quicksand, sans-serif',
      minWidth: '140px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      flex: '1',
    }}
  >
    Sale Info
  </div>
  <div
    className={`header-button ${activeTab === 'Tokenomics' ? 'active' : ''}`}
    onClick={() => setActiveTab('Tokenomics')}
    style={{
      background: activeTab === 'Tokenomics' ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : 'rgba(22, 26, 66, 0.70)',
      color: activeTab === 'Tokenomics' ? '#F1F8FD' : '#7B91B0',
      borderTopLeftRadius: '0px', // Top left corner
      borderTopRightRadius: '0px', // Top right corner
      borderBottomLeftRadius: '0px', // Bottom left corner
      borderBottomRightRadius: '0px', // Bottom right corner
      border: '2.59px #3461FF solid',
      padding: '10px 20px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '20px',
      fontFamily: 'Quicksand, sans-serif',
      minWidth: '140px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      flex: '1',
    }}
  >
    Tokenomics
  </div>
  <div
    className={`header-button ${activeTab === 'Description' ? 'active' : ''}`}
    onClick={() => setActiveTab('Description')}
    style={{
      background: activeTab === 'Description' ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : 'rgba(22, 26, 66, 0.70)',
      color: activeTab === 'Description' ? '#F1F8FD' : '#7B91B0',
      borderTopLeftRadius: '0px', // Top left corner
      borderTopRightRadius: '8px', // Top right corner
      borderBottomLeftRadius: '0px', // Bottom left corner
      borderBottomRightRadius: '8px', // Bottom right corner
      border: '2.59px #3461FF solid',
      padding: '10px 20px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '20px',
      fontFamily: 'Quicksand, sans-serif',
      minWidth: '140px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      flex: '1',
    }}
  >
    Project
  </div>
</div>




<div className='row mt-5'>
  {[
    { label: "Name", value: stats.pool.name },
    { label: "Symbol", value: stats.pool.symbol },
    { label: "Decimals", value: stats.pool.decimals },
    {
      label: "Contract",
      value: (
        <span style={{ whiteSpace: 'pre-line', display: 'flex', alignItems: 'center' }}>
          <span style={{ 
            background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)', 
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontSize: '16px', 
            fontWeight: 400,
            fontFamily: 'Quicksand, sans-serif',
            color: '#F1F8FD', // fallback color
            display: 'inline-block'
          }}>
            {tokenAddr}
          </span>
          <CopyToClipboard
            text={stats.pool.token?.toString()}
            onCopy={() => {
              setRefcopy2(true);
              setTimeout(() => {
                setRefcopy2(false);
              }, 2000);
            }}
          >
            <span
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginLeft: '5px',
                width: '16px',
                height: '16px',
                background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
                WebkitMaskImage: `url(${iconImage})`,
                WebkitMaskRepeat: 'no-repeat',
                WebkitMaskSize: 'contain',
                WebkitMaskPosition: 'center',
              }}
            >
            </span>
          </CopyToClipboard>
          <span>{refcopy2 && "done"}</span>
        </span>
      )
    },
    { label: "Selling", value: `${formatPrice(stats.pool.saleToken)} ${stats.pool.symbol}` },
    { label: "Soft Cap", value: `${stats.pool.softCap} SOL` },
    { label: "Unsold", value: stats.pool.refundType === "0" ? "Refund" : "Burn" },
    { label: "Start", value: dateFormat(startTime, "yyyy-mm-dd HH:MM") },
    { label: "End", value: dateFormat(endTime, "yyyy-mm-dd HH:MM") },
    { label: "Listing", value: "Raydium" },
    { label: "Liquidity %", value: `${stats.pool.liquidityPercent} %` },
    { label: "Unlock", value: `${Number(stats.pool.liquidityLockdays?.toNumber())} days` }
  ].map((item, index) => (
    <div
      key={index}
      className='col-12 my-2 d-flex justify-content-between'
      style={{
        fontFamily: 'Quicksand, sans-serif',
        fontWeight: 400,
        borderBottom: '1px solid rgba(128, 128, 128, 0.3)',
        paddingBottom: '8px'
      }}
    >
      <p style={{ fontSize: '16px', fontWeight: 400, color: '#98A7B5' }}>{item.label}:</p> {/* Grey color for labels */}
      <p style={{ 
        fontSize: '16px', 
        fontWeight: 400, 
        color: '#F1F8FD'  // White color for values
      }}>{item.value}</p>
    </div>
  ))}
</div>

                </div>
              </div>
              <div className='col-12 col-md-4'>
              <div className='card project-card no-hover'>
                <div
                  className='card-header'
                 style={{height: "100px"}}><img src={bannerURL} alt="Banner"
                    style={{ objectFit: "cover" }} onError={() => setBannerImage()}></img>
                </div>
                 <div className='card-body'>
                  <div className="d-flex justify-content-between pb-3" style={{ marginTop: '-33px', zIndex: '100', position: 'relative' }} >
                     {/* {stats.pool.kyc==1&& <div className="kyc-audit">KYC</div>}*/}
                    {/*    <div className="kyc-audit">SAFU</div>*/}
                    {/*  {stats.pool.audit==1&& <div className="kyc-audit">AUDIT</div>}*/}
                  </div>
                  <div className='mt-md-0 mt-3 d-flex justify-content-center'>
                    <div className='countdown'>
                      <div
                        className='text-center'
                        style={{ fontSize: "1.2rem" }}
                      >
                        {parseInt(stats.pool.endTime) <
                        Math.floor(currentTime)
                          ? "Ended"
                          : parseInt(stats.pool.startTime) >
                            Math.floor(currentTime)
                          ? "Start In"
                          : parseInt(stats.pool.startTime) <
                              Math.floor(currentTime) &&
                            parseInt(stats.pool.endTime) >
                              Math.floor(currentTime)
                          ? "End In"
                          : ""}
                      </div>
                      <Countdown
                        key={Math.floor(Math.random() * 10 + 1)}
                        date={
                          stats.pool.startTime > currentTime
                            ? stats.pool.startTime
                            : stats.pool.endTime
                        }
                        renderer={countdownrender}
                      />
                    </div>
                  </div>
                  <div className='item-progress'>
                    <div className='progress'>
                      <div
                        className='progress-bar'
                        role='progressbar'
                        style={{
                          width: `${parseFloat(stats.pool.percentageRaise).toFixed(
                            2
                          )}%`,
                        }}
                        aria-valuenow={parseFloat(
                          stats.pool.percentageRaise
                        ).toFixed(2)}
                        aria-valuemin='0'
                        aria-valuemax='100'
                      >
                        {parseFloat(stats.pool.percentageRaise).toFixed(2)}%
                      </div>
                    </div>
                    <div className='progress-sale d-flex justify-content-between'>
                      <span>0 SOL</span>
                      <span>
                        Softcap ~ {stats.pool.softCap} SOL
                      </span>
                    </div>
                  </div>
                  <div className='staking-area mt-4'>
                    <div className='staking-card single-staking'>
                      <p>Amount</p>
                      <div className='input-box my-1'>
                        <div className='input-area d-flex flex-column'>
                          <div className='input-text'>
                            <input
                              type='text'
                              value={amount}
                              onChange={(e) => {
                                handleChangeAmount(e);
                              }}
                              placeholder='0.00'
                            />
                            <a
                              href='#sec'
                              onClick={(e) => {
                                handleMaxAmount(e);
                              }}
                            >
                              Max
                            </a>
                          </div>
                          <div className='mt-2'>
                            <Button
                              variant='none'
                              disabled={btndisabled}
                              loading={contributeLoading}
                              type='button'
                              onClick={(e) => {
                                handleSubmitContribution(e);
                              }}
                              className='btn btn-bordered-white'
                            >
                              SUBMIT
                            </Button>
                          </div>
                        </div>
                        <span className='text-danger'>
                          <small>{error_msg}</small>
                        </span>
                      </div>
                        <React.Fragment>
                          <p className='mt-4'>Your Contribution</p>
                          <span className='mt-0 mb-3'>
                            {accStats.contributionOf
                              ? formatPrice(accStats.contributionOf)
                              : "0"}{" "}
                            SOL
                          </span>
                        </React.Fragment>
                      {/* <div className="input-area d-flex flex-column flex-md-row mb-3"> */}
                      {stats.pool.poolState === 1 && (
                        <React.Fragment>
                          <p className='mb-15'>Your Claimble Token</p>
                          <span className='mt-0 mb-3'>
                            {accStats.userAvalibleClaim
                              ? formatPrice(accStats.userAvalibleClaim)
                              : "0"}{" "}
                            {stats.pool.symbol}
                          </span>
                          <Button
                            loading={ctLoading}
                            variant='none'
                            className='btn input-btn mt-2 mt-md-0 mr-md-3'
                            onClick={(e) => handleClaimToken(e)}
                          >
                            Claim Token
                          </Button>
                        </React.Fragment>
                      )}
                      {accStats.contributionOf > 0 &&
                        (stats.pool.poolState === 2 ||
                          stats.pool.poolState === 0) && (
                          <>
                            <Button
                              loading={wcLoading}
                              className='btn input-btn mt-4 mt-md-0 mr-md-3'
                              onClick={(e) => handleWithdrawContribution(e)}
                            >
                              Withdraw Contribution
                            </Button>
                            <small className='text-danger'>
                              *Early withdrawal of 10% penalty
                            </small>
                          </>
                        )}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='card project-card no-hover staking-card single-staking'>
                
                {stats.pool.governance?.toString() &&
                publicKey?.toString() &&
                stats.pool.governance.toLowerCase() === publicKey?.toString().toLowerCase() ? (
                  <React.Fragment>
                    <div className='input-box my-1'>
                      <div className='input-area d-flex justify-content-center flex-column flex-md-row mb-3'>
                        {stats.pool.poolState === 0 && (
                          <Button
                            variant='none'
                            loading={locklLoading}
                            onClick={(e) => handleCancel(e)}
                            type='button'
                            className='btn btn-bordered-white mt-2 mt-md-0 ml-md-3'
                          >
                            cancel
                          </Button>
                        )}
                        {stats.pool.poolState === 0 &&
                          (
                            stats.pool.totalRaised >= stats.pool.softCap) && (
                            <Button
                              variant='none'
                              type='button'
                              loading={finalLoading}
                              onClick={(e) => handleFinalize(e)}
                              className='btn btn-bordered-white mt-2 mt-md-0 ml-md-3'
                            >
                              Finalize
                            </Button>
                          )}
                              {stats.pool.poolState === 1 &&
                            <Button
                              type='button'
                              loading={locklLoading}
                              onClick={(e) => handleWithdrawLiquidity(e)}
                              className='btn btn-bordered-white mt-2 mt-md-0 ml-md-3'
                            >
                              Withdraw Liquidity
                            </Button>
                          }
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className='d-flex justify-content-center'>
                    <h6 className='mt-4'>Admin panel.</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
          )}
          
        </section>
      </div>
      
      
      <Modal
        show={editmodalShow}
        onHide={() => setEditmodalShow(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Details</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 mb-0'>
                <div className='form-group'>
                  <label>
                    Logo URL<span className='text-danger'>*</span>
                  </label>
                  <input
                    className='form-control'
                    onChange={(e) => onChangeInput(e)}
                    type='text'
                    value={editSocial.logourl}
                    name='logourl'
                    placeholder='e.g. https://i.ibb.co/pvyN7PB/testimage.png'
                  />
                  <small className='text-danger'>{error.logourl}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-6 mb-0'>
                <div className='form-group'>
                  <label>
                    Banner Image URL<span className='text-danger'>*</span>
                  </label>
                  <input
                    className='form-control'
                    onChange={(e) => onChangeInput(e)}
                    type='text'
                    value={editSocial.bannerurl}
                    name='bannerurl'
                    placeholder='e.g. https://i.ibb.co/QjW6pvx/testbanner.png'
                  />
                  <small className='text-danger'>{error.bannerurl}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-6 mb-0'>
                <div className='form-group'>
                  <label>
                    Website*<span className='text-danger'>*</span>
                  </label>
                  <input
                    className='form-control'
                    onChange={(e) => onChangeInput(e)}
                    type='text'
                    value={editSocial.website}
                    name='website'
                    placeholder='e.g. https://defilaunch.app'
                  />
                  <small className='text-danger'>{error.website}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-6 mb-0'>
                <div className='form-group'>
                  <label>Facebook</label>
                  <input
                    className='form-control'
                    onChange={(e) => onChangeInput(e)}
                    type='text'
                    value={editSocial.facebook}
                    name='facebook'
                    placeholder='e.g. https://www.facebook.com/'
                  />
                  <small className='text-danger'>{error.facebook}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-6 mb-0'>
                <div className='form-group'>
                  <label>Twitter</label>
                  <input
                    className='form-control'
                    onChange={(e) => onChangeInput(e)}
                    type='text'
                    value={editSocial.twitter}
                    name='twitter'
                    placeholder='e.g. https://twitter.com/'
                  />
                  <small className='text-danger'>{error.twitter}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-6 mb-0'>
                <div className='form-group'>
                  <label>Github</label>
                  <input
                    className='form-control'
                    onChange={(e) => onChangeInput(e)}
                    type='text'
                    value={editSocial.github}
                    name='github'
                    placeholder='e.g. https://github.com/'
                  />
                  <small className='text-danger'>{error.github}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-6 mb-0'>
                <div className='form-group'>
                  <label>Telegram</label>
                  <input
                    className='form-control'
                    onChange={(e) => onChangeInput(e)}
                    type='text'
                    name='telegram'
                    value={editSocial.telegram}
                    placeholder='e.g. https://t.me/BlockStar_Social_Media'
                  />
                  <small className='text-danger'>{error.telegram}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-6 mb-0'>
                <div className='form-group'>
                  <label>Instagram</label>
                  <input
                    className='form-control'
                    onChange={(e) => onChangeInput(e)}
                    type='text'
                    name='instagram'
                    value={editSocial.instagram}
                    placeholder='e.g. https://www.instagram.com/'
                  />
                  <small className='text-danger'>{error.instagram}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-6 mb-0'>
                <div className='form-group'>
                  <label>Discord</label>
                  <input
                    className='form-control'
                    onChange={(e) => onChangeInput(e)}
                    type='text'
                    name='discord'
                    value={editSocial.discord}
                    placeholder='e.g. https://discord.com/'
                  />
                  <small className='text-danger'>{error.discord}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-6 mb-0'>
                <div className='form-group'>
                  <label>Reddit</label>
                  <input
                    className='form-control'
                    onChange={(e) => onChangeInput(e)}
                    type='text'
                    name='reddit'
                    value={editSocial.reddit}
                    placeholder='e.g. https://reddit.com/'
                  />
                  <small className='text-danger'>{error.reddit}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-6 mb-0'>
                <div className='form-group'>
                  <label>Youtube Video</label>
                  <input
                    className='form-control'
                    onChange={(e) => onChangeInput(e)}
                    type='text'
                    name='youtube'
                    value={editSocial.youtube}
                    placeholder='e.g. https://youtube.com/watch'
                  />
                  <small className='text-danger'>{error.youtube}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-12 mb-0'>
                <div className='form-group'>
                  <label>Description</label>
                  <textarea
                    type='text'
                    style={{ height: "167px" }}
                    name='brief'
                    onChange={(e) => onChangeInput(e)}
                    className='brief'
                    placeholder='Project Brief'
                  >
                    {editSocial.brief}
                  </textarea>
                  <small className='text-danger'>{error.brief}</small>
                  <br />
                </div>
              </div>
              <div className='col-md-12 mt-4 mb-0 d-flex justify-content-center'>
                <Button
                  className='btn btn-success'
                  loading={editLoading}
                  onClick={(e) => handleEditSubmit(e)}
                >
                  submit
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}
