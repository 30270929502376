import { PublicKey } from '@solana/web3.js';
import { MPL_PROGRAM_ID } from './constant';
import { deserializeMetadata } from '@metaplex-foundation/mpl-token-metadata';
import {
    MarketV2,
    buildSimpleTransaction,
    SPL_ACCOUNT_LAYOUT
} from '@raydium-io/raydium-sdk';

export const isSolAddress = (address) => {
  try {
    if (!address) return false;
    // Regular expressions for validation
    // const lengthRegex = /^.{44}$/; // Check for a string of exactly 50 characters
    const validCharsRegex = /^[a-zA-Z0-9]+$/; // Check if the string consists of only letters and numbers

    // Validate the string
    // const isLengthValid = lengthRegex.test(address);
    const containsValidChars = validCharsRegex.test(address);

    const isStringValid = containsValidChars;
    if (isStringValid) {
      // const publicKey = new PublicKey(address);
      const isValidAddress = PublicKey.isOnCurve(address);
      return isValidAddress;
    } else return isStringValid;
  } catch (err) {
    console.log(err)
    return false
  }
}

export const getMetadataPda = (tokenMint) => {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from("metadata"),
      MPL_PROGRAM_ID.toBuffer(),
      tokenMint.toBuffer(),
    ],
    MPL_PROGRAM_ID,
  )[0];
}

export const getTokenNameSymbol = async (connection, tokenMint) => {
  const metadataPDA = getMetadataPda(tokenMint);
  const metadataAccount = await connection.getAccountInfo(metadataPDA);

  if (metadataAccount) {
    const metadata = deserializeMetadata({
      publicKey: metadataPDA,
      data: metadataAccount.data
    });
    let tokenName = metadata.name;
    let tokenSymbol = metadata.symbol;
    return { tokenName, tokenSymbol };
  }
  else {
    return null;
  }

};

export const formatPrice = (num) => {
  //return parseFloat(num.toFixed(decimals)).toLocaleString();
  return new Intl.NumberFormat('ja-JP').format(parseFloat(num).toFixed(3));
}

export const numToUint8Array = (num) => {
  let arr = new Uint8Array(8);

  for (let i = 0; i < 8; i++) {
    arr[i] = num % 256;
    num = Math.floor(num / 256);
  }

  return arr;
}

export const uint8ArrayToNum = (arr) => {
  let num = 0;

  for (let i = 0; i < 8; i++) {
    num += Math.pow(256, i) * arr[i];
  }

  return num;
}

