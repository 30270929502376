import {
  PublicKey,
  ComputeBudgetProgram
} from "@solana/web3.js";
import {
  ENDPOINT as _ENDPOINT,
  Currency,
  DEVNET_PROGRAM_ID,
  LOOKUP_TABLE_CACHE,
  MAINNET_PROGRAM_ID,
  RAYDIUM_MAINNET,
  Token,
  TxVersion,
  Liquidity
} from '@raydium-io/raydium-sdk';
import {
  Program,
  AnchorProvider,
  Idl,
  setProvider,
} from "@coral-xyz/anchor";

import RAYDIUM_CP_SWAP_IDL from "../json/raydium_sp_swap_idl.json";

export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
};


// export const ADMIN_PUBKEY = new PublicKey("CWPwv1m7DSJPUEBMXTY98KVhnYmrdNrQSzgkTtu9c2Gj");  //real
 


// dev
export const ADMIN_PUBKEY = new PublicKey("2H1evxbt6WhHaaLftD1UhqRnSPqQsEikqm6XzXZ1hNSy");

export const BaseScanUrl = 'https://explorer.solana.com/';

export const TOTAL_SUPPLY_LIMIT = 18446744073709551616n; //2^64

export const MPL_PROGRAM_ID = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s");

export const FAIR_SALE_PROGRAM_ID = new PublicKey('5CraxV2tk4ZqxX2kqRsPU97TwbK8hiKRK8RLDZDNQn7u');

export const WSOL_MINT = new PublicKey("So11111111111111111111111111111111111111112");


export const GLOBAL_INFO_PUB_KEY = new PublicKey('4xJJ4pmPx1HVf2FvV9vATbdz7YXLmpUPfHkDpyFwRknc');

export const LOCK_PROGRAM_ID = new PublicKey("78Z5ne4gtT8zUqLqLew45a3MxkvZ38ntHugzGkKStAuy");

export const RAYDIUM_CP_SWAP_PROGRAM_ID = new PublicKey('CPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C'); //mainnet
// export const RAYDIUM_CP_SWAP_PROGRAM_ID = new PublicKey('CPMDWBwJDtYax9qW7AyRuVC19Cc4L4Vcy4n2BHAbHkCW'); //devnet

  // export const RAYDIUM_CP_SWAP_POOL_FEE=process.env.REACT_APP_MODE === "testnet"
  // ?"G11FKBRaAkHAKuLCgLM6K6NUc9rTjPAznRCjZifrTQe2"
// :"DNXgeM9EiiaAbaWvwjHj9fQQLAX5ZsfHyvmYUNRAdNC8"
// export const RAYDIUM_CP_SWAP_POOL_FEE = "G11FKBRaAkHAKuLCgLM6K6NUc9rTjPAznRCjZifrTQe2"; //devnet
export const RAYDIUM_CP_SWAP_POOL_FEE= "DNXgeM9EiiaAbaWvwjHj9fQQLAX5ZsfHyvmYUNRAdNC8"; //mainnet


export const DEV_NET_RPC_URL = "https://api.devnet.solana.com";
export const MAIN_NET_RPC_RUL = "https://wider-blue-hill.solana-mainnet.quiknode.pro/fda6a69b241aeb944b38bffae97a0bae26136503";

export const CURRENT_RPC_URL = MAIN_NET_RPC_RUL;

export const CURRENT_RAYDIUM_PROGRAM = MAINNET_PROGRAM_ID;

export const MODIFY_COMPUTE_UNITS = ComputeBudgetProgram.setComputeUnitLimit({
  units: 10000000
});

export const ADD_PRIORITY_FEE = ComputeBudgetProgram.setComputeUnitPrice({
  microLamports: 1000000
});