import {
    PublicKey,
} from "@solana/web3.js";


export function u16ToBytes(num) {
    const arr = new ArrayBuffer(2)
    const view = new DataView(arr)
    view.setUint16(0, num, false)
    return new Uint8Array(arr)
}

export async function getAmmConfigAddress(
    index,
    programId
) {
    const [address, bump] = await PublicKey.findProgramAddressSync(
        [Buffer.from("amm_config"), u16ToBytes(index)],
        programId
    );
    return [address, bump];
}

export async function getAuthAddress(
    programId
) {
    const [address, bump] = await PublicKey.findProgramAddressSync(
        [Buffer.from("vault_and_lp_mint_auth_seed")],
        programId
    );
    return [address, bump];
}

export async function getPoolAddress(
    ammConfig,
    tokenMint0,
    tokenMint1,
    programId
) {
    const [address, bump] = await PublicKey.findProgramAddressSync(
        [
            Buffer.from("pool"),
            ammConfig.toBuffer(),
            tokenMint0.toBuffer(),
            tokenMint1.toBuffer(),
        ],
        programId
    );
    return [address, bump];
}

export async function getPoolLpMintAddress(
    pool,
    programId
) {
    const [address, bump] = await PublicKey.findProgramAddressSync(
        [Buffer.from("pool_lp_mint"),pool.toBuffer()],
        programId
    );
    return [address, bump];
}

export async function getPoolVaultAddress(
    pool,
    vaultTokenMint,
    programId
  ){
    const [address, bump] = await PublicKey.findProgramAddressSync(
      [Buffer.from("pool_vault"), pool.toBuffer(), vaultTokenMint.toBuffer()],
      programId
    );
    return [address, bump];
  }