import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState, useCallback } from "react";
import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import IDL from '../../../../json/idl.json';
import { CURRENT_RPC_URL, FAIR_SALE_PROGRAM_ID } from "../../../../hooks/constant";
import { Connection } from '@solana/web3.js';
import { getTokenNameSymbol } from "../../../../hooks/helper";

const global = new PublicKey("CmSDqcCxKFZmx3hPvEXC5kvDsrsrXtMrTVhntAye2gbn");

export const usePoolListStats = (updater, myContribution = false) => {
  const [currentTime] = useState(Math.floor(new Date().getTime()));
  let { page, pageSize, loading } = updater;
  let { publicKey } = useWallet();
  const connection = new Connection(CURRENT_RPC_URL, 'confirmed');
  const [stats, setStats] = useState(
    { poolList: [], loading: loading }
  );
  const [myStats, setMyStats] = useState(
    { poolList: [], loading: loading }
  );

  const fetchAllFairSalePDAs = useCallback(async () => {
    let provider;
    if (publicKey) {
      provider = new anchor.AnchorProvider(connection, publicKey, {});
    } else {
      provider = new anchor.AnchorProvider(connection, global, {});
    }
    const programId = FAIR_SALE_PROGRAM_ID;
    const program = new Program(IDL, programId, provider);
    let fair_sale_pdas = await program.account.fairSale.all();

    let favoriteAddresses = JSON.parse(localStorage.getItem('favorites')) || [];

    let poolList = fair_sale_pdas.map((value) => {
      let status = "";
      if (value.account.poolState === 1) {
        status = "completed";
      } else if (value.account.poolState === 2) {
        status = "canceled";
      } else if (parseInt(value.account.endTime.toNumber() * 1000) < currentTime) {
        status = "ended";
      } else if (parseInt(value.account.startTime.toNumber() * 1000) > currentTime) {
        status = "upcoming";
      } else if (parseInt(value.account.startTime.toNumber() * 1000) < currentTime &&
        parseInt(value.account.endTime.toNumber() * 1000) > currentTime) {
        status = "active";
      }

      let poolDetails = value.account.poolDetails.toString();
      let x = {
        kyc: value.account.kycStatus,
        audit: value.account.auditStatus,
        decimals: value.account.tokenDecimal,
        name: value.account.tokenName ? value.account.tokenName : '',
        symbol: value.account.tokenSymbol ? value.account.tokenSymbol : '',
        poolAddress: value.publicKey,
        currencySymbol: "SOL",
        endTime: value.account.endTime.toNumber() * 1000,
        liquidityPercent: value.account.liquidityPercent,
        poolState: value.account.poolState,
        poolDetails: value.account.poolDetails,
        softCap: value.account.softcap.toNumber() / Math.pow(10, 9),
        startTime: value.account.startTime.toNumber() * 1000,
        token: value.account.splMint,
        totalRaised: value.account.totalRaised.toNumber() / Math.pow(10, 9),
        percentageRaise:
          (value.account.totalRaised.toNumber() / value.account.softcap) * 100,
        logourl: poolDetails.split("$#$")[0],
        bannerurl: poolDetails.split("$#$")[1],
        website: poolDetails.split("$#$")[2],
        blockstar: poolDetails.split("$#$")[3],
        facebook: poolDetails.split("$#$")[4],
        twitter: poolDetails.split("$#$")[5],
        github: poolDetails.split("$#$")[6],
        telegram: poolDetails.split("$#$")[7],
        instagram: poolDetails.split("$#$")[8],
        discord: poolDetails.split("$#$")[9],
        reddit: poolDetails.split("$#$")[10],
        youtube: poolDetails.split("$#$")[11],
        brief: poolDetails.split("$#$")[12],
        saleToken: value.account.saleToken.toNumber() / Math.pow(10, value.account.tokenDecimal),
        status,
        contributors: value.account.contributors,
        isFavorited: favoriteAddresses.includes(value.publicKey.toString()), // Add isFavorited property
      };
      return x;
    });

    poolList.sort((a, b) => b.status.localeCompare(a.status) || a.totalRaised - b.totalRaised);
    setStats({ poolList: poolList, loading: false });

    if (myContribution) {
      let myPoolList = poolList.filter((pool) => {
        if (pool.contributors) {
          for (let i = 0; i < pool.contributors.length; i++) {
            if (pool.contributors[i].contributorAddress?.toString() === publicKey?.toString()) {
              return true;
            }
          }
        }
        return false;
      });
      setMyStats({ poolList: myPoolList, loading: false });
    }
  }, [publicKey]);

  useEffect(() => {
    fetchAllFairSalePDAs();
  }, [fetchAllFairSalePDAs]);

  if (myContribution) return myStats;
  else return stats;
};

export const usePoolStats = (updater, address) => {
  let { loading } = updater;
  let { publicKey } = useWallet();
  const [currentTime] = useState(Math.floor(new Date().getTime()));
  const [stats, setStats] = useState(
    {
      pool: {
        kyc: 0,
        audit: 0,
        decimals: 0,
        name: '',
        symbol: '',
        poolAddress: '',
        currencySymbol: "SOL",
        endTime: currentTime,
        liquidityPercent: 0,
        poolState: '',
        poolDetails: '',
        softCap: 0,
        startTime: currentTime,
        token: '',
        totalRaised: 0,
        percentageRaise: 0,
        logourl: '',
        bannerurl: '',
        website: '',
        blockstar: '',
        facebook: '',
        twitter: '',
        github: '',
        telegram: '',
        instagram: '',
        discord: '',
        reddit: '',
        youtube: '',
        brief: '',
        governance: '',
        saleToken: 0,
        status: ''
      }, loading: loading
    }
  );

  const connection = new Connection(CURRENT_RPC_URL, 'confirmed');

  const fetchFairSalePDA = useCallback(async (address) => {
    const addr_account = new PublicKey(address);

    let provider;
    if (publicKey) {
      provider = new anchor.AnchorProvider(connection, publicKey, {});
    } else {
      provider = new anchor.AnchorProvider(connection, global, {});
    }
    const programId = FAIR_SALE_PROGRAM_ID;
    const program = new Program(IDL, programId, provider);
    let value = await program.account.fairSale.fetch(addr_account);

    let status = "";
    if (value.poolState === 1) {
      status = "completed";
    } else if (value.poolState === 2) {
      status = "canceled";
    } else if (
      parseInt(value.endTime.toNumber() * 1000) < currentTime
    ) {
      status = "ended";
    } else if (
      parseInt(value.startTime.toNumber() * 1000) > currentTime
    ) {
      status = "upcoming";
    } else if (
      parseInt(value.startTime.toNumber() * 1000) < currentTime &&
      parseInt(value.endTime.toNumber() * 1000) > currentTime
    ) {
      status = "active";
    }
    let poolDetails = value.poolDetails.toString();
    let x = {
      governance: value.governance.toString(),
      kyc: value.kycStatus,
      audit: value.auditStatus,
      decimals: value.tokenDecimal,
      name: value.tokenName ? value.tokenName : '',
      symbol: value.tokenSymbol ? value.tokenSymbol : '',
      poolAddress: addr_account,
      currencySymbol: "SOL",
      endTime: value.endTime.toNumber() * 1000,
      liquidityPercent: value.liquidityPercent,
      poolState: value.poolState,
      poolDetails: value.poolDetails,
      softCap: value.softcap.toNumber() / Math.pow(10, 9),
      startTime: value.startTime.toNumber() * 1000,
      token: value.splMint,
      totalRaised: value.totalRaised.toNumber() / Math.pow(10, 9),
      percentageRaise:
        (value.totalRaised.toNumber() / value.softcap) * 100,
      logourl: poolDetails.split("$#$")[0],
      bannerurl: poolDetails.split("$#$")[1],
      website: poolDetails.split("$#$")[2],
      blockstar: poolDetails.split("$#$")[3],
      facebook: poolDetails.split("$#$")[4],
      twitter: poolDetails.split("$#$")[5],
      github: poolDetails.split("$#$")[6],
      telegram: poolDetails.split("$#$")[7],
      instagram: poolDetails.split("$#$")[8],
      discord: poolDetails.split("$#$")[9],
      reddit: poolDetails.split("$#$")[10],
      youtube: poolDetails.split("$#$")[11],
      brief: poolDetails.split("$#$")[12],
      liquidityLockdays: value.liquidityLockdays,
      contributors: value.contributors,
      saleToken: value.saleToken.toNumber() / Math.pow(10, value.tokenDecimal),
      status
    };
    loading = false;
    setStats({ pool: x, loading });
  }, [address]);

  useEffect(() => {
    fetchFairSalePDA(address);
  }, [fetchFairSalePDA]);

  return stats;
};

export const useAccountStats = (updater, poolStats) => {
  let { contributors, totalRaised, saleToken } = poolStats;
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const [stats, setStats] = useState({
    balance: 0,
    contributionOf: 0,
    userAvalibleClaim: "",
  });

  const updateAccStats = useCallback(async () => {
    try {
      const balance = await connection.getBalance(publicKey);
      let contributionOf = 0;
      let userAvalibleClaim = 0;
      for (let i = 0; i < contributors?.length; i++) {
        if (contributors[i].contributorAddress.toString() === publicKey.toString()) {
          contributionOf = contributors[i].contributedAmount.toNumber() / 10 ** 9;
          userAvalibleClaim = (contributionOf / totalRaised) * saleToken;
        }
      }
      setStats({
        balance,
        contributionOf,
        userAvalibleClaim
      });
    } catch (e) {
      console.log("error on fetch abalance", e);
    }
  }, [publicKey, contributors]);

  useEffect(() => {
    updateAccStats();
  }, [updateAccStats]);

  return stats;
};
