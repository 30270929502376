import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import templateImg from "../images/template.jpg";
import bannerTemplate from "../images/template_banner.png";
import approvedIcon from "../images/approved-icon-1.png";
import notApprovedIcon from "../images/not-approved-icon-3.png";
import solNetworkIcon from "../images/SolNetwork.svg";
import discordIcon from "../images/discord.svg";
import telegramIcon from "../images/telegram.svg";
import websiteIcon from "../images/website.svg";
import twitterIcon from "../images/x.svg";
import defaultIcon from "../images/DiamondDefaultIcon.svg";
import selectedIcon from "../images/DiamondSelectedIcon.svg";

export default function ProjectCard({ rowdata, index, onFavoriteToggle }) {
    const [imgageURL, setImageURL] = useState(rowdata.logourl);
    const [bannerURL, setBannerURL] = useState(rowdata.bannerurl);
    const [isFavorited, setIsFavorited] = useState(rowdata.isFavorited);

    useEffect(() => {
        // Ensure the localStorage is in sync with the component's state
        const storedFavorites = localStorage.getItem("favorites");
        const favoriteAddresses = storedFavorites ? JSON.parse(storedFavorites) : [];
        setIsFavorited(favoriteAddresses.includes(rowdata.poolAddress.toString()));
    }, [rowdata.poolAddress]);

    const toggleFavorite = () => {
        let storedFavorites = localStorage.getItem("favorites");
        let favoriteAddresses = storedFavorites ? JSON.parse(storedFavorites) : [];

        if (isFavorited) {
            // Remove from favorites
            favoriteAddresses = favoriteAddresses.filter(address => address !== rowdata.poolAddress.toString());
        } else {
            // Add to favorites
            favoriteAddresses.push(rowdata.poolAddress.toString());
        }

        localStorage.setItem("favorites", JSON.stringify(favoriteAddresses));
        setIsFavorited(!isFavorited);

        if (onFavoriteToggle) {
            onFavoriteToggle();
        }
    };

    const setimage = () => {
        setImageURL(templateImg);
    };

    const setBannerImage = () => {
        setBannerURL(bannerTemplate);
    };

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case "active":
                return "#1cbc23";
            case "upcoming":
                return "#f4a261";
            case "cancelled":
                return "#e76f51";
            default:
                return "#999999";
        }
    };

    return (
        <div className="col-12 col-sx-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 item explore-item mb-4" key={index}>
            <div style={{ borderRadius: "8px", display: "flex", maxWidth: "320px", flexDirection: "column", justifyContent: "center", background: "linear-gradient(90deg, #6604FD 0%, #01F4A4 100%)", padding: "3px" }}>
                <div className="card project-card" style={{ borderRadius: "8px", display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "#111415", overflow: "hidden", width: "100%" }}>
                    <div className="card-header" style={{ borderRadius: "6.65px 6.65px 0 0", background: "transparent", minHeight: "95px", display: "flex", width: "100%", flexDirection: "column", overflow: "hidden", position: "relative", marginBottom: "0", paddingBottom: "0" }}>
                        <div style={{ position: "absolute", top: "8px", left: "8px", backgroundColor: "#111415", borderRadius: "13.3px", display: "flex", alignItems: "center", padding: "5px 10px" }}>
                            <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: getStatusColor(rowdata.status), boxShadow: `0 0 8px ${getStatusColor(rowdata.status)}`, marginRight: "5px" }}></div>
                            <span style={{ color: "#FFFFFF", fontSize: "10px", fontWeight: "normal" }}>{rowdata.status.toUpperCase()}</span>
                        </div>
                        <img src={bannerURL} alt="Banner" style={{ borderRadius: "6.65px 6.65px 0 0", objectFit: "cover", width: "100%", height: "95px", border: "none", marginBottom: "0", paddingBottom: "0" }} onError={setBannerImage} />
                        <img src={isFavorited ? selectedIcon : defaultIcon} alt="Favorite" style={{ position: "absolute", top: "8px", right: "8px", cursor: "pointer", width: "24px", height: "24px" }} onClick={toggleFavorite} />
                    </div>
                    <div className="card-body" style={{ padding: "5px", width: "100%", borderRadius: "8px" }}>
                        <div className="d-flex justify-content-between pb-3" style={{ marginTop: "-8px" }}>
                            <div className="d-flex">
                                <div className="project-logo" style={{ paddingRight: "10px", marginTop: "15px", borderRadius: "50%", background: "linear-gradient(180deg, #6604FD 0%, #01F4A4 100%)", padding: "2px", display: "inline-block" }}>
                                    <img src={imgageURL} width="50px" onError={setimage} alt="logo" style={{ borderRadius: "50%", minWidth: "50px", objectFit: "cover", display: "block", backgroundColor: "#111415", padding: "1px" }} />
                                </div>
                                <div>
                                    <div>
                                        <Link to={`/fairlaunch-details/${rowdata.poolAddress?.toString()}`}>
                                            <h5 className="mx-0 mt-2 mb-0" style={{ paddingTop: "8px", paddingLeft: "10px" }}>{rowdata.name ? rowdata.name : ""}</h5>
                                        </Link>
                                    </div>
                                    <div className="social-icons" style={{ marginTop: "2px", paddingLeft: "10px" }}>
                                        {rowdata.website && (
                                            <a target="_blank" rel="noreferrer" href={rowdata.website}>
                                                <img src={websiteIcon} alt="Website" style={{ width: "24px", marginRight: "5px" }} />
                                            </a>
                                        )}
                                        {rowdata.twitter && (
                                            <a target="_blank" rel="noreferrer" href={rowdata.twitter}>
                                                <img src={twitterIcon} alt="Twitter" style={{ width: "24px", marginRight: "5px" }} />
                                            </a>
                                        )}
                                        {rowdata.discord && (
                                            <a target="_blank" rel="noreferrer" href={rowdata.discord}>
                                                <img src={discordIcon} alt="Discord" style={{ width: "24px", marginRight: "5px" }} />
                                            </a>
                                        )}
                                        {rowdata.telegram && (
                                            <a target="_blank" rel="noreferrer" href={rowdata.telegram}>
                                                <img src={telegramIcon} alt="Telegram" style={{ width: "24px", marginRight: "5px" }} />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div style={{ backgroundColor: "#0B0D21", borderRadius: "8px", padding: "5px", display: "flex", alignItems: "center", justifyContent: "center", width: "45px", height: "45px", marginTop: "15px", marginRight: "10px" }}>
                                <img src={solNetworkIcon} alt="Network" style={{ width: "20px" }} />
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", backgroundColor: "#0B0D21", borderRadius: "8px", padding: "8px", marginBottom: "8px", border: "1.66px solid rgba(255, 255, 255, 0.1)" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src={notApprovedIcon} alt="Not Approved" style={{ width: "21px", height: "21px", marginRight: "8px" }} />
                                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "13px", fontWeight: "400", color: "#FFFFFF" }}>AUDIT</span>
                            </div>
                            <div className="divider" style={{ height: "21px", borderLeft: "1px solid rgba(255, 255, 255, 0.2)" }}></div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src={approvedIcon} alt="SAFU" style={{ width: "21px", height: "21px", marginRight: "8px" }} />
                                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "13px", fontWeight: "400", color: "#1cbc23" }}>SAFU</span>
                            </div>
                            <div className="divider" style={{ height: "21px", borderLeft: "1px solid rgba(255, 255, 255, 0.2)" }}></div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src={notApprovedIcon} alt="Not Approved" style={{ width: "21px", height: "21px", marginRight: "8px" }} />
                                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "13px", fontWeight: "400", color: "#FFFFFF" }}>KYC</span>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#0B0D21", borderRadius: "8px", padding: "7px", color: "#FFFFFF", border: "1.66px solid rgba(255, 255, 255, 0.1)", marginBottom: "5px" }}>
                            <div style={{ marginBottom: "10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                                    <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "13px", fontWeight: "400", color: "#FFFFFF" }}>PROGRESS:</span>
                                    <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "13px", fontWeight: "400", color: "#FFFFFF" }}>{parseFloat(rowdata.percentageRaise).toFixed(2)}%</span>
                                </div>
                                <div style={{ height: "10px", borderRadius: "5px", backgroundColor: "#A020F0" }}>
                                    <div
                                        style={{
                                            height: "100%",
                                            borderRadius: "5px",
                                            backgroundColor: rowdata.percentageRaise === 0 ? "#A020F0" : "#1cbc23", // Purple for 0%, Green for 1% and above
                                            width: `${parseFloat(rowdata.percentageRaise).toFixed(2)}%`
                                        }}
                                    ></div>
                                </div>
                            </div>
                            <div style={{ marginBottom: "5px" }}>
                                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "13px", fontWeight: "400", color: "#FFFFFF" }}>SOFTCAP:</span>
                                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "13px", fontWeight: "400", color: "#FFFFFF", float: "right" }}>{rowdata.softCap} SOL</span>
                            </div>
                            <div>
                                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "13px", fontWeight: "400", color: "#FFFFFF" }}>START TIME:</span>
                                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "13px", fontWeight: "400", color: "#FFFFFF", float: "right" }}>{dateFormat(new Date(rowdata.startTime), "yyyy-mm-dd HH:MM")}</span>
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <Link to={`/fairlaunch-details/${rowdata.poolAddress?.toString()}`} className="btn btn-smaller w-100" style={{ background: "linear-gradient(180deg, #6600FF 0%, #20A595 100%)", borderRadius: "8px", color: "#FFFFFF", textAlign: "center", padding: "12px 0", fontFamily: "Quicksand, Helvetica", fontSize: "16px", fontWeight: "400" }}>
                                VIEW SALE
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
