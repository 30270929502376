import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from 'react-icons/ai';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import axios from "axios";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import solLogo from "../images/sol_logo.png"; // Solana logo
import defiLaunchLogo from "../images/logo.png"; // Defi Launch logo
import diamondIcon from "../images/DiamondIconBottom.svg"; // Diamond icon
import networkButton from "../images/Networkbutton.svg"; // Network button

export const Connect = ({ setOpen }) => {
  const [buttonText, setButtonText] = useState('Select Wallet');
  const [solPrice, setSolPrice] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const updateButtonText = () => {
      if (window.innerWidth <= 576) {
        setButtonText('Connect');
      } else {
        setButtonText('Select Wallet');
      }
    };

    updateButtonText();
    window.addEventListener('resize', updateButtonText);

    return () => {
      window.removeEventListener('resize', updateButtonText);
    };
  }, []);

  useEffect(() => {
    const fetchSolPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd"
        );
        setSolPrice(response.data.solana.usd);
      } catch (error) {
        console.error("Error fetching SOL price:", error);
      }
    };

    fetchSolPrice();

    const interval = setInterval(fetchSolPrice, 60000); // Update price every minute
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handleFavoritesClick = () => {
    navigate('/favorites'); // Navigate to the favorites page
  };

  const connectButtonStyle = {
    backgroundColor: '#161A42',
    color: '#FFFFFF',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'normal',
    borderRadius: '15px',
    textAlign: 'center',
    border: '1px solid transparent',
    backgroundClip: 'padding-box',
    transition: 'box-shadow 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const connectButtonBeforeStyle = {
    content: '""',
    position: 'absolute',
    top: '-1px',
    right: '-1px',
    bottom: '-1px',
    left: '-1px',
    borderRadius: '15px',
    background: 'linear-gradient(to right, #00cdac, #8ddad5)',
    zIndex: '-1',
  };

  const priceButtonStyle = {
    backgroundColor: '#161A42',
    color: '#fff',
    borderRadius: '10px',
    padding: '10px 15px',
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    marginRight: '20px',
  };

  const solLogoStyle = {
    height: '14px',
    marginRight: '5px',
    border: 'none',
  };

  return (
    <div className="d-flex align-items-center justify-content-between" style={{ width: '100%', paddingTop: '15px' }}>
      <div className="d-block d-sm-none mr-3" style={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
        <AiOutlineMenu />
      </div>
      <div className="d-flex align-items-center">
        <a href="https://defilaunch.app/" target="_blank" rel="noopener noreferrer" className="network-button d-none d-sm-block">
          <img src={networkButton} alt="Defi Launch" />
        </a>
        <div className="diamond-icon-container d-none d-sm-block" onClick={handleFavoritesClick}>
          <img src={diamondIcon} alt="Favorites" className="diamond-icon" />
        </div>
      </div>
      <div className="logo-container d-none d-md-block">
        <img src={defiLaunchLogo} alt="Defi Launch" className="defi-launch-logo" />
      </div>
      <div className="d-flex align-items-center" style={{ position: 'relative' }}>
        {solPrice !== null ? (
          <button style={priceButtonStyle} className="btn sol-price-button">
            <img src={solLogo} alt="Solana Logo" style={solLogoStyle} />
            ${solPrice.toFixed(2)}
          </button>
        ) : (
          <button style={priceButtonStyle} className="btn sol-price-button">
            <img src={solLogo} alt="Solana Logo" style={solLogoStyle} />
            Loading...
          </button>
        )}
        <div style={{ position: 'relative', paddingTop: '0px', paddingRight: '0px' }}>
          <WalletMultiButton className="connect-button" style={connectButtonStyle} />
          <div style={connectButtonBeforeStyle}></div>
        </div>
      </div>

      <style jsx>{`
        .diamond-icon-container {
          display: flex;
          align-items: center;
          margin-right: 20px;
          cursor: pointer; /* Add cursor pointer to indicate it's clickable */
        }

        .diamond-icon {
          width: 23px;
          height: 25px;
        }

        .network-button {
          margin-right: 15px; /* Add padding to the right */
        }

        .network-button img {
          width: 100%; /* Display at half the size on desktop */
          cursor: pointer;
        }

        .logo-container {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
        }

        .defi-launch-logo {
          height: 40px;
        }

        @media (max-width: 768px) {
          .network-button,
          .diamond-icon-container {
            display: none; /* Hide on mobile */
          }

          .logo-container {
            position: static;
            transform: none;
            justify-content: center;
            width: 100%;
          }

          .defi-launch-logo {
            height: 30px; /* Adjust size for mobile */
          }
        }
      `}</style>
    </div>
  );
};

export default Connect;
