import { useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { WalletProvider, ConnectionProvider, useWallet } from '@solana/wallet-adapter-react';
import {
    WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';

import MainLayout from "./layouts/main";
import MainToken from "./pages/token/MainToken";
import TokenDetails from './pages/token/TokenDetails';
import Fairsale from './pages/launchpadApply/Fairsale';
import DetailsFairComp from './pages/launchpadApply/fairsale/fairsaleview/DetailsFairComp';
import ProjectDetails from './pages/launchpadApply/fairsale/fairsaleview/ProjectDetails';
import MyContributions from "./pages/launchpadApply/SaleList/component/MyContributions";
import ProjectList from "./pages/launchpadApply/SaleList/ProjectList";
import Favorites from './pages/launchpadApply/SaleList/Favorites';  // Import the Favorites component
import { CURRENT_RPC_URL } from './hooks/constant';

import { FavoritesProvider } from './contexts/FavoritesContext';  // Import the FavoritesProvider

function App() {
  const network = CURRENT_RPC_URL;
  const { select, wallets, publicKey, disconnect, connecting } = useWallet();
  
  return (
    <div className="App">
      <ConnectionProvider endpoint={network}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <FavoritesProvider>  {/* Wrap the app with FavoritesProvider */}
              <ToastContainer pauseOnFocusLoss={false} />
              <Router>
                <MainLayout>
                  <Routes>
                    <Route path='/' element={<ProjectList />} />
                    <Route path='/sale-list' element={<ProjectList />} />
                    <Route path='/my-contribution' element={<MyContributions/> } />
                    <Route path='/token' element={<MainToken />} />
                    <Route path='/token-details' element={<TokenDetails />} />
                    <Route path='/fairlaunch' element={<Fairsale />} />
                    <Route path='/fairlaunch-details/:address' element={<ProjectDetails/>}/>
                    <Route path='/favorites' element={<Favorites />} />  {/* Add the Favorites route */}
                  </Routes>
                </MainLayout>      
              </Router>
            </FavoritesProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </div>
  );
}

export default App;
