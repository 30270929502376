import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import ProjectCard from "../../../component/ProjectCard";
import { usePoolListStats } from "./helper/useStats";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UniswapImage from '../../../images/Banner1.png';
import BetasImage from '../../../images/Banner3.png';
import PancakesImage from '../../../images/Banner2.png';

import FairLaunchIcon from "../../../images/FairLaunchIconBottom.svg";
import BondingIcon from "../../../images/BondingIconBottom.svg";
import HomeIcon from "../../../images/HomeIconBottom.svg";
import DiamondIcon from "../../../images/DiamondIconBottom.svg";
import CenterShadow from "../../../images/CenterShadowBehindHomeIcon.svg";
import CreateTokenIcon from '../../../images/CreateCoinIcon.svg';
import BondingCurveIcon from '../../../images/PumpSaleIcon.svg';

export default function ProjectList() {
  const [updater, setUpdater] = useState({
    page: 0,
    pageSize: 30,
    loading: true,
  });

  // Default to Basic Mode and Fair Launch
  const [degenMode, setDegenMode] = useState(false);
  const [isBonding, setIsBonding] = useState(false); // Fair Launch as default
  const [fullView, setFullView] = useState(false);
  const stats = usePoolListStats(updater);
  const navigate = useNavigate();

  const handleFavoritesClick = () => {
    navigate("/favorites");
  };

  const handleCreateTokenClick = () => {
    navigate("/token");
  };

  const handleCreateLaunchClick = () => {
    navigate("/fairlaunch");
  };

  const handleBondingCurveClick = () => {
    navigate("/bonding-token-sale");
  };

  const handleHomeClick = () => {
    setFullView(false); // Show the carousel and header when Home is selected
  };

  const handleFullViewClick = () => {
    setFullView(true); // Hide the carousel and header when Full View is selected
  };

  const handleFavoriteToggle = () => {
    setUpdater(prev => ({ ...prev }));
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <div className="container project-list-container" style={containerStyle}>
      {!fullView && (
        <>
          <div className="custom-carousel-container" style={{ ...sectionSpacing, paddingTop: '20px', paddingBottom: '0px' }}>
            <Slider {...settings} className="carousel-banner">
              <div style={bannerStyles}>
                <img src={UniswapImage} alt="Uniswap" style={imageStyles} />
              </div>
              <div style={bannerStyles}>
                <img src={BetasImage} alt="Betas" style={imageStyles} />
              </div>
              <div style={bannerStyles}>
                <img src={PancakesImage} alt="Pancakes" style={imageStyles} />
              </div>
            </Slider>
          </div>
        </>
      )}
      {!fullView && (
        <div className="mobile-header-wrapper">
          <div className="header-content mobile-header">
            <span style={mobileHeaderTextStyles} className="header-text">
              <span style={mobileFairLaunchTextStyles}>
                {isBonding ? "⭐️ Pump Launch " : "🔥 Fair Launch "}
              </span>
              <span style={mobileDividerTextStyles}> &nbsp;&gt;&nbsp; </span>
              <span style={mobileLiveSalesTextStyles} className="live-sales-text">
                Live
              </span>
            </span>
            {!isBonding && (
              <Link to="/token" style={mobileCreateButtonStyles} className="create-button">
                CREATE COIN
              </Link>
            )}
            {isBonding && (
              <Link to="/bonding-token-sale" style={mobileCreateButtonStyles} className="create-button">
                CREATE SALE
              </Link>
            )}
          </div>
        </div>
      )}

      {/* Mobile Header Buttons */}
      <div className="header-buttons-container mobile-header" style={{ paddingTop: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2px' }}>
        <div className="buttons-wrapper" style={{ display: 'flex', flexDirection: 'row', gap: '0px', flexWrap: 'nowrap', width: '100%' }}>
          <button
            className={`explore-btn degen-btn ${degenMode ? "active" : ""}`}
            style={{
              ...mobileButtonStyles,
              background: degenMode ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
              color: degenMode ? '#fff' : '#7B91B0',
              borderTopLeftRadius: '4px', 
              borderBottomLeftRadius: '4px', 
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              paddingLeft: '6px', 
            }}
          >
            Degen
          </button>
          <button
            className={`explore-btn basic-btn ${!degenMode ? "active" : ""}`}
            style={{
              ...mobileButtonStyles,
              background: !degenMode ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
              color: !degenMode ? '#fff' : '#7B91B0',
              borderTopRightRadius: '4px', 
              borderBottomRightRadius: '4px',
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            }}
          >
            Basic
          </button>
          <button
            className={`explore-btn fairlaunch-btn ${!isBonding ? "active" : ""}`}
            style={{
              ...mobileButtonStyles,
              background: !isBonding ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
              color: !isBonding ? '#fff' : '#7B91B0',
              borderTopLeftRadius: '4px', 
              borderBottomLeftRadius: '4px',
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              marginLeft: '6px', 
            }}
          >
            Fair
          </button>
          <button
            className={`explore-btn bonding-btn ${isBonding ? "active" : ""}`}
            style={{
              ...mobileButtonStyles,
              background: isBonding ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
              color: isBonding ? '#fff' : '#7B91B0',
              borderTopRightRadius: '4px', 
              borderBottomRightRadius: '4px',
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            }}
            onClick={() => alert("Coming soon...")}
          >
            Pump
          </button>
          <button
            className={`explore-btn home-btn ${!fullView ? "active" : ""}`}
            style={{
              ...mobileButtonStyles,
              background: !fullView ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
              color: !fullView ? '#fff' : '#7B91B0',
              borderTopLeftRadius: '4px', 
              borderBottomLeftRadius: '4px', 
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              marginLeft: '6px', 
            }}
            onClick={handleHomeClick}
          >
            Home
          </button>
          <button
            className={`explore-btn fullview-btn ${fullView ? "active" : ""}`}
            style={{
              ...mobileButtonStyles,
              background: fullView ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
              color: fullView ? '#fff' : '#7B91B0',
              borderTopRightRadius: '4px', 
              borderBottomRightRadius: '4px', 
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
              paddingRight: '6px', 
            }}
            onClick={handleFullViewClick}
          >
            Full
          </button>
        </div>
      </div>

      <div className="header-buttons-container desktop-header" style={headerButtonsContainerStyles}>
        {/* Desktop Header */}
        <div className="header-content desktop-header">
          <span style={headerTextStyles} className="header-text">
            <span style={fairLaunchTextStyles}>{isBonding ? "⭐️ Pump Launch" : "🔥 Fair Launch"}</span>
            <span style={dividerTextStyles}> &nbsp;&gt;&nbsp; </span>
            <span style={liveSalesTextStyles} className="live-sales-text"> Live Sales</span>
          </span>
          {isBonding && (
            <>
              <Link to="/bonding-token-sale" style={{ ...createButtonStyles, background: 'linear-gradient(90deg, #6CA0DC 0%, #00308F 100%)' }} className="create-button">CREATE SALE</Link>
            </>
          )}
          {!isBonding && (
            <>
              <Link to="/token" style={{ ...createButtonStyles, background: 'linear-gradient(90deg, #6CA0DC 0%, #00308F 100%)' }} className="create-button">CREATE TOKEN</Link>
              <Link to="/fairlaunch" style={{ ...createButtonStyles, background: 'linear-gradient(90deg, #6CA0DC 0%, #00308F 100%)' }} className="create-launch-button">CREATE LAUNCH</Link>
            </>
          )}
        </div>
        <div className="buttons-wrapper" style={buttonsWrapperStyles}>
          <div style={buttonContainerStyles}>
            <button
              className={`explore-btn degen-btn ${degenMode ? "active" : ""}`}
              style={{
                ...buttonStyles,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                background: degenMode ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                color: degenMode ? '#fff' : '#7B91B0',
              }}
            >
              Degen Mode
            </button>
            <button
              className={`explore-btn basic-btn ${!degenMode ? "active" : ""}`}
              style={{
                ...buttonStyles,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                background: !degenMode ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                color: !degenMode ? '#fff' : '#7B91B0',
              }}
            >
              Basic Mode
            </button>
          </div>
          <div style={buttonContainerStyles}>
            <button
              className={`explore-btn fairlaunch-btn ${!isBonding ? "active" : ""}`}
              style={{
                ...buttonStyles,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                background: !isBonding ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                color: !isBonding ? '#fff' : '#7B91B0',
              }}
            >
              Fair Launch
            </button>
            <button
              className={`explore-btn bonding-btn ${isBonding ? "active" : ""}`}
              style={{
                ...buttonStyles,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                background: isBonding ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                color: isBonding ? '#fff' : '#7B91B0',
              }}
              onClick={() => alert("Coming soon...")}
            >
              Pump Sales
            </button>
          </div>
          <div style={buttonContainerStyles}>
            <button
              className={`explore-btn home-btn ${!fullView ? "active" : ""}`}
              style={{
                ...buttonStyles,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                paddingLeft: '0px', 
                paddingRight: '0px', 
                background: !fullView ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                color: !fullView ? '#fff' : '#7B91B0',
              }}
              onClick={handleHomeClick}
            >
              Home
            </button>
            <button
              className={`explore-btn fullview-btn ${fullView ? "active" : ""}`}
              style={{
                ...buttonStyles,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                paddingLeft: '0px', 
                paddingRight: '0px', 
                background: fullView ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                color: fullView ? '#fff' : '#7B91B0',
              }}
              onClick={handleFullViewClick}
            >
              Full View
            </button>
          </div>
        </div>
      </div>
      <div className="row project-cards-container" style={projectCardsContainerStyle}>
        {stats.loading ? (
          <div className="col-md-12">
            <HashLoader
              size="100"
              color="#fff"
              cssOverride={{
                left: "50%",
                textAlign: "center",
                top: "50%",
              }}
            />
          </div>
        ) : (
          stats.poolList.length > 0 &&
          stats.poolList.map((rowdata, index) => (
            <ProjectCard 
              rowdata={rowdata} 
              index={index} 
              key={rowdata.poolAddress} 
              onFavoriteToggle={handleFavoriteToggle} 
            />
          ))
        )}
      </div>

 {/* Mobile Bottom Navigation Bar */}
<div className="bottom-navigation mobile-bottom-navigation">
  <img src={CreateTokenIcon} alt="Create Token" className="bottom-navigation-icon" onClick={handleCreateTokenClick} />
  <img src={BondingCurveIcon} alt="Bonding Curve" className="bottom-navigation-icon grayed-out-icon" />
  <div className="center-icon-container" onClick={handleHomeClick}>
    <img src={CenterShadow} alt="Center Shadow" className="center-shadow" />
    <img src={HomeIcon} alt="Home" className="bottom-navigation-icon center-icon" />
  </div>
  <img src={FairLaunchIcon} alt="Fair Launch" className="bottom-navigation-icon" onClick={handleCreateLaunchClick} />
  <img src={DiamondIcon} alt="Diamond" className="bottom-navigation-icon" onClick={handleFavoritesClick} />
</div>
    </div>
  );
}

const containerStyle = {
  padding: '0', 
};

const sectionSpacing = {
  padding: '9px 0',
};

const projectCardsContainerStyle = {
  marginTop: '0px',
  paddingTop: '0px',
};

const bannerStyles = {
  padding: '0 0px', 
};

const imageStyles = {
  width: '100%',
  height: 'auto',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

const headerButtonsContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0', 
  marginBottom: '10px',
};

const headerTextStyles = {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Quicksand, sans-serif',
  fontWeight: '400', 
  fontSize: '18px', 
  lineHeight: '20px',
};

const fairLaunchTextStyles = {
  color: '#6EE7B7',
};

const dividerTextStyles = {
  color: '#7B91B0',
};

const liveSalesTextStyles = {
  color: '#6EE7B7',
};

const createButtonStyles = {
  marginLeft: '20px',
  padding: '8px 18px',
  fontSize: '12px',
  fontWeight: '400', 
  color: '#fff',
  border: '1.42px solid #3461FF',
  borderRadius: '8px',
  boxShadow: '1.9710144996643066px 18.724637985229492px 30.550724029541016px rgba(0, 0, 0, 0.50)',
  cursor: 'pointer',
  height: '35px',
  background: 'linear-gradient(180deg, #FF0090 0%, #FF0090 100%)'
};

const buttonsWrapperStyles = {
  display: 'flex',
  gap: '8px',
};

const buttonContainerStyles = {
  display: 'flex',
  gap: '0',
};

const buttonStyles = {
  padding: '4px 4px', 
  fontSize: '10px',
  fontWeight: 'normal', 
  color: '#7B91B0',
  border: '1.42px solid #3461FF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '35px',
  borderRadius: '8px',
  margin: 0,
  minWidth: '80px',
  textDecoration: 'none',
};

const projectCardStyle = {
  margin: '0', 
  padding: '0', 
};

const mobileHeaderTextStyles = {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Quicksand, sans-serif',
  fontWeight: '400',
  fontSize: '14px', 
  lineHeight: '16px',
  margin: '0', 
};

const mobileFairLaunchTextStyles = {
  color: '#6EE7B7',
  fontSize: '15px', 
};

const mobileDividerTextStyles = {
  color: '#7B91B0',
  fontSize: '14px', 
};

const mobileLiveSalesTextStyles = {
  color: '#6EE7B7',
  fontSize: '15px', 
};

const mobileCreateButtonStyles = {
  marginLeft: '50px',
  marginRight: '0px',
  padding: '6px 24px',
  fontSize: '10px',
  fontWeight: '400',
  color: '#fff',
  border: '1.42px solid #3461FF',
  borderRadius: '8px',
  boxShadow: '1.9710144996643066px 18.724637985229492px 30.550724029541016px rgba(0, 0, 0, 0.50)',
  cursor: 'pointer',
  height: '30px',
  background: 'linear-gradient(90deg, #6CA0DC 0%, #00308F 100%)'
};

const mobileButtonStyles = {
  padding: '4px 6px', 
  fontSize: '9px',
  flex: 1,
  whiteSpace: 'nowrap', 
  textAlign: 'center',
  boxSizing: 'border-box', 
  border: '1.42px solid #3461FF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '25px',
  margin: 0, 
  minWidth: '40px',
};

const mobileStyles = `
  @media (max-width: 600px) {
    .desktop-header {
      display: none !important;
      marginBottom: '10px',
    }
    .mobile-header {
      display: flex !important;
      marginBottom: '10px',
    }
    .header-text {
      font-size: 12px;
      font-weight: 400;
    }
    .header-content {
      font-size: 8px;
      font-weight: 400;
      margin: 0;
    }

    .create-button {
      font-size: 10px;
      padding: 4px 10px;
      margin: 0; 
    }

    .project-cards-container {
      padding-bottom: 80px; /* Padding at the bottom for mobile view */
    }

    .explore-btn.degen-btn {
      border-top-left-radius: 4px; 
      border-bottom-left-radius: 4px; 
      padding-left: 6px;
    }

    .explore-btn.basic-btn {
      border-top-right-radius: 4px; 
      border-bottom-right-radius: 4px;
    }

    .explore-btn.fairlaunch-btn {
      border-top-left-radius: 4px; 
      border-bottom-left-radius: 4px;
      margin-left: 6px; 
    }

    .explore-btn.bonding-btn {
      border-top-right-radius: 4px; 
      border-bottom-right-radius: 4px;
    }

    .explore-btn.home-btn {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      margin-left: 6px;
    }

    .explore-btn.fullview-btn {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding-right: 6px; 
    }

    .buttons-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow-x: hidden;
      padding: 0 10px;
    }
    .header-buttons-container {
      flex-direction: column;
      align-items: center;
      width: 100%; 
      margin-bottom: 10px;
    }

    .mobile-bottom-navigation {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      position: fixed;
      bottom: 0px;
      width: 100%;
      background: #161A42;
      padding: 0px 0;
      z-index: 1000;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    
    }

    .bottom-navigation-icon {
      width: 20px;
      height: 20px;
    }

    .center-icon-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 73px;
      height: 59px;
      background: #232966;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      bottom: 0px;
    }
    
    .center-icon {
      width: 60px;
      height: 60px;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-left: 5px;
      padding-top: 15px:
      margin-top: 5px; 

    }
    
    
    .center-shadow {
      width: 73px;
      height: 50px;
      position: absolute;
      bottom: -30px;
    }
  }
  @media (min-width: 601px) {
    .desktop-header {
      display: flex !important;
    }
    .mobile-header, .mobile-bottom-navigation {
      display: none !important;
    }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = mobileStyles;
document.head.appendChild(styleSheet);
