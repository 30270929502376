import React, { useState, useMemo, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Menu, MenuItem, ProSidebar, SidebarHeader, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import styled from 'styled-components';
import queryString from 'query-string';
import logo from '../images/logo_main.png';
import logoIcon from '../images/logo.png';
import LaunchpadIcon from '../images/LaunchpadIcon.png';
import SwapIcon from '../images/SwapIcon.svg';
import BridgeIcon from '../images/BridgeIcon.svg';
import PortfolioIcon from '../images/PortfolioIcon.svg';
import SocialsIcon from '../images/SocialsIcon.svg';
import CreateIcon from '../images/CreateIcon.svg';
import CreateTokenIcon from '../images/CreateTokenIcon.png';
import FairLaunchIcon from '../images/FairLaunchIcon.svg';
import BondingCurveIcon from '../images/BondingCurveIcon.svg';
import AirdropIcon from '../images/AirdropIcon.svg';
import LockIcon from '../images/LockIcon.svg';
import StakingIcon from '../images/StakingIcon.png';
import HelpVideosIcon from '../images/HelpVideosIcon.svg';
import ViewIcon from '../images/ViewIcon.svg';

// Add a link to the Google Fonts in the return statement
const GoogleFontsLink = () => (
  <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet" />
);

// Styled component for menu items
const Menuitem = styled(MenuItem)`
  :hover {
    background-color: #ffdb58;
    padding: 5px;
    border-radius: 10px;
  }
  font-size: 16px;
  color: ${({ grayedOut }) => (grayedOut ? 'gray' : 'white')};
  pointer-events: ${({ grayedOut }) => (grayedOut ? 'none' : 'auto')};
`;

// Styled component for SubMenu titles
const CustomSubMenu = styled(SubMenu)`
  font-size: 16px;
  color: white;

  ${({ collapsed }) => collapsed && `
    .pro-inner-list-item {
      display: none;
    }
  `}
`;

// Styled component for icons with linear gradient
const Icon = styled.img`
  width: ${({ size }) => size || '1.5em'};
  height: ${({ size }) => size || '1.5em'};
`;

const SidebarWrapper = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  color: white;
`;

export function useRouter() {
  const params = useParams();
  const location = useLocation();

  return useMemo(() => {
    return {
      push: location.push,
      replace: location.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      location,
    };
  }, [params, location]);
}

export default function Sidebar({ open, setOpen }) {
  const router = useRouter();
  const [width, setWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 575); // Set initial state based on screen width
  
  const isMobile = width <= 575;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const onClickMenuItem = () => {
    setCollapsed(true);
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  
  useEffect(() => {
    if (isMobile) setCollapsed(false);
  }, [isMobile]);

  const renderContent = (
    <SidebarWrapper>
      <GoogleFontsLink />
      <ProSidebar collapsed={collapsed}>
        <SidebarHeader>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px' }}>
            <img src={collapsed ? logoIcon : logo} alt="Brand Logo" height="40px" />
            {!isMobile && (
              <div className="toggleMenu" onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? <FaAngleRight /> : <FaAngleLeft />}
              </div>
            )}
          </div>
          {isMobile && (<div className="toggleMenu" onClick={() => setOpen(false)}>
              <FaAngleLeft />
            </div>)}
        </SidebarHeader>
        <Menu iconShape="" style={{ background: 'none' }}>
          <Menuitem 
            icon={<Icon src={ViewIcon} style={{ width: '32px', height: '32px' }} />} 
            style={{ background: '#5610D9', cursor: 'default' }}
          >
            View
          </Menuitem>
          <Menuitem 
            icon={<Icon src={LaunchpadIcon} />} 
            className={`${router.pathname === "/" ? "active" : ""}`}
            style={{ background: 'none' }}
          >
            <Link to="/" onClick={onClickMenuItem} style={{ color: 'white' }}>Launchpad</Link>
          </Menuitem>

          <CustomSubMenu 
            title="Socials" 
            icon={<Icon src={SocialsIcon} />} 
            className={`${router.pathname.startsWith("/socials") ? "active" : ""}`}
            collapsed={collapsed} // Pass the collapsed state to the CustomSubMenu
            style={{ background: 'none' }}
          >
            <MenuItem 
              onClick={() => setOpen(false)} 
              className={`${router.pathname === "/socials/discord" ? "active" : ""}`}
              style={{ background: 'none' }}
            >
              <a href="https://discord.com/invite/TsvvJUS58m" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Discord</a>
            </MenuItem>
            <MenuItem 
              onClick={() => setOpen(false)} 
              className={`${router.pathname === "/socials/twitter" ? "active" : ""}`}
              style={{ background: 'none' }}
            >
              <a href="https://twitter.com/defilaunchapp" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Twitter</a>
            </MenuItem>
            <MenuItem 
              onClick={() => setOpen(false)} 
              className={`${router.pathname === "/socials/telegram" ? "active" : ""}`}
              style={{ background: 'none' }}
            >
              <a href="https://t.me/defilaunchapp" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Telegram</a>
            </MenuItem>
            <MenuItem 
              onClick={() => setOpen(false)} 
              className={`${router.pathname === "/socials/warpcast" ? "active" : ""}`}
              style={{ background: 'none' }}
            >
              <a href="https://warpcast.com/~/invite-page/398484?id=e17eaba6" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Warpcast</a>
            </MenuItem>
          </CustomSubMenu>
          <hr style={{ border: '0px solid #075985', marginBottom: '20px' }} />
          <Menuitem 
            icon={<Icon src={CreateIcon} style={{ width: '32px', height: '32px' }} />} 
            style={{ background: '#5610D9', cursor: 'default' }}
          >
            Create
          </Menuitem>
          <Menuitem 
            icon={<Icon src={CreateTokenIcon} />} 
            className={`${router.pathname === "/token" ? "active" : ""}`}
            style={{ background: 'none' }}
          >
            <Link to="/token" onClick={onClickMenuItem} style={{ color: 'white' }}>Meme Coin</Link>
          </Menuitem>
          <Menuitem 
            icon={<Icon src={FairLaunchIcon} />} 
            className={`${router.pathname === "/fairlaunch" ? "active" : ""}`}
            style={{ background: 'none' }}
          >
            <Link to="/fairlaunch" onClick={onClickMenuItem} style={{ color: 'white' }}>Fair Launch</Link>
          </Menuitem>

          <hr style={{ border: '0.5px solid #075985', marginBottom: '20px' }} />
          <Menuitem 
            icon={<Icon src={HelpVideosIcon} />} 
            onClick={() => setOpen(false)}
            style={{ background: 'none' }}
          >
            <a href="https://www.youtube.com/channel/UC6khIxCjTEAQc_YqYpMxTVg" target="_blank" style={{ color: 'white' }}>Help Videos</a>
          </Menuitem>
        </Menu>
      </ProSidebar>
    </SidebarWrapper>
  );

  return (
    <>
      {!isMobile && (
        <div style={{ position: 'fixed', top: 10, left: 10, cursor: 'pointer', zIndex: 1000 }} onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <FaAngleRight size={24} /> : <FaAngleLeft size={24} />}
        </div>
      )}
      {isMobile ? open && (
        <div style={{ width: "320px", height: '100vh', position: 'absolute' }}>
          {renderContent}
        </div>
      ) : renderContent}
    </>
  );
}
