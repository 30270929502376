import React from "react";
import { Link } from "react-router-dom";
import ProjectCard from "../../../component/ProjectCard";
import { usePoolListStats } from "./helper/useStats";

const Favorites = () => {
    const stats = usePoolListStats({ page: 0, pageSize: 100 });

    // Log the poolList data to ensure it's being retrieved correctly
    console.log("Stats Pool List:", stats.poolList);

    const favoriteProjects = stats.poolList.filter(pool => {
        const favoriteAddresses = JSON.parse(localStorage.getItem("favorites")) || [];
        return favoriteAddresses.includes(pool.poolAddress.toString());
    });

    // Log the filtered favorites to check if they are being filtered correctly
    console.log("Favorite Projects:", favoriteProjects);

    const handleFavoriteToggle = () => {
        const updatedFavorites = stats.poolList.filter(pool => {
            const favoriteAddresses = JSON.parse(localStorage.getItem("favorites")) || [];
            return favoriteAddresses.includes(pool.poolAddress.toString());
        });
        return updatedFavorites;
    };

    return (
        <div className="container favorites-container" style={containerStyle}>
            {/* Desktop Header */}
            <div className="header-buttons-container desktop-header" style={headerButtonsContainerStyles}>
                <div className="header-content desktop-header">
                    <span style={headerTextStyles} className="header-text">
                        <span style={fairLaunchTextStyles}>🔥 Fair Launch</span>
                        <span style={dividerTextStyles}> &nbsp;&gt;&nbsp; </span>
                        <span style={liveSalesTextStyles} className="live-sales-text"> Favorites</span>
                    </span>
                    <Link to="/token" style={createButtonStyles} className="create-button">CREATE TOKEN</Link>
                </div>
            </div>

            {/* Mobile Header */}
            <div className="header-buttons-container mobile-header" style={mobileHeaderButtonsContainerStyles}>
                <div style={mobileHeaderContainerStyle}>
                    <span style={mobileHeaderTextStyles} className="header-text">
                        <span style={mobileFairLaunchTextStyles}>🔥 Fair Launch &gt; Favorites</span>
                    </span>
                    <Link to="/token" style={mobileCreateButtonStyles} className="create-button">CREATE TOKEN</Link>
                </div>
            </div>

            {/* Project Cards */}
            <div className="row project-cards-container" style={projectCardsContainerStyle}>
                {favoriteProjects.length > 0 ? (
                    favoriteProjects.map((rowdata, index) => (
                        <ProjectCard 
                            key={rowdata.poolAddress} 
                            rowdata={rowdata} 
                            onFavoriteToggle={handleFavoriteToggle}
                        />
                    ))
                ) : (
                    <p style={messageStyle}>No favorites selected!</p>
                )}
            </div>
        </div>
    );
};

const containerStyle = {
    padding: '0',
};

const projectCardsContainerStyle = {
    marginTop: '20px',
    paddingTop: '10px',
};

const headerButtonsContainerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    marginBottom: '10px',
};

const headerTextStyles = {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '20px',
};

const fairLaunchTextStyles = {
    color: '#6EE7B7',
};

const dividerTextStyles = {
    color: '#7B91B0',
};

const liveSalesTextStyles = {
    color: '#6EE7B7',
};

const createButtonStyles = {
    marginLeft: '20px',
    padding: '8px 18px',
    fontSize: '12px',
    fontWeight: '400',
    color: '#fff',
    border: '1.42px solid #3461FF',
    borderRadius: '8px',
    boxShadow: '1.971px 18.725px 30.551px rgba(0, 0, 0, 0.50)',
    cursor: 'pointer',
    height: '35px',
    background: 'linear-gradient(90deg, #6CA0DC 0%, #00308F 100%)'
};

// Mobile specific styles
const mobileHeaderButtonsContainerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px', 
    marginBottom: '10px',
    marginTop: '20px',  
};

const mobileHeaderContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center',
    width: '100%',
};

const mobileHeaderTextStyles = {
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '400',
    fontSize: '14px',  
    lineHeight: '14px',
};

const mobileFairLaunchTextStyles = {
    color: '#6EE7B7',
    textAlign: 'left',
    display: 'inline',
};

const mobileCreateButtonStyles = {
    padding: '5px 12px',
    fontSize: '10px',
    fontWeight: '400',
    color: '#fff',
    border: '1.42px solid #3461FF',
    borderRadius: '8px',
    boxShadow: '1.971px 18.725px 30.551px rgba(0, 0, 0, 0.50)',
    cursor: 'pointer',
    height: '30px',
    background: 'linear-gradient(90deg, #6CA0DC 0%, #00308F 100%)',
    textAlign: 'center',
};

const messageStyle = {
    fontSize: '12px',
    color: '#FFFFFF',
    paddingTop: '20px',
    textAlign: 'center',  
    width: '100%',        
    margin: '0 auto',     
};

export default Favorites;
