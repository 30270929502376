import React, { useContext, useState, useCallback } from "react";
import Context from "./context/Context";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap-button-loader";
import { toast } from "react-toastify";

import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import {
  sol,
  generateSigner,
  percentAmount,
  signerIdentity,
  createSignerFromKeypair,
  transactionBuilder
} from '@metaplex-foundation/umi';

import { mplCandyMachine } from "@metaplex-foundation/mpl-candy-machine";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import {
  createMetadataAccountV3,
  TokenStandard,
  createAndMint
} from '@metaplex-foundation/mpl-token-metadata';

import { setAuthority, AuthorityType } from '@metaplex-foundation/mpl-toolbox';

import {
  fromWeb3JsInstruction,
  toWeb3JsPublicKey
} from '@metaplex-foundation/umi-web3js-adapters';

import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";

import {
  Keypair,
  LAMPORTS_PER_SOL,
  SystemProgram,
} from '@solana/web3.js';

import { ADMIN_PUBKEY, TOTAL_SUPPLY_LIMIT } from "../../hooks/constant";

export default function StandardToken(props) {
  const { connection } = useConnection();
  const { wallet } = useWallet();
  const { createFee } = props;
  const navigate = useNavigate();
  const { value, setValue } = useContext(Context);
  const [createloading, setCreateloading] = useState(false);
  const [error, setError] = useState({
    name: "",
    symbol: "",
    decimals: "",
    supply: "",
    uri: "",
  });

  const checkStandardValidation = (input, inputValue) => {
    let terror = 0;
    let message = "";
    var reg;
    switch (input) {
      case "name":
        if (inputValue === "") {
          terror += 1;
          message = "Please Input Token Name!";
        } else if (inputValue.length >= 50) {
          message = "Token name must be less than 50 characters!";
          terror += 1;
        } else {
          message = "";
        }
        break;
      case "symbol":
        if (inputValue === "") {
          terror += 1;
          message = "Please Input Token Symbol!";
        } else if (inputValue.length >= 50) {
          message = "Token symbol must be less than 50 characters!";
          terror += 1;
        } else {
          message = "";
        }
        break;
      case "decimals":
        inputValue = parseFloat(inputValue);
        reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Amount!";
        } else if (parseFloat(inputValue) <= 1) {
          terror += 1;
          message = "Decimals must be 2 at least!";
        } else if (parseFloat(inputValue) > 9) {
          terror += 1;
          message = "Decimals must be less than 9!";
        } else {
          message = "";
        }
        break;
      case "supply":
        inputValue = parseFloat(inputValue);
        reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Amount!";
        } else if (parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Supply must not be zero!";
        } else {
          message = "";
        }
        break;
      default:
        terror += 0;
        break;
    }

    if (terror > 0) {
      setError({ ...error, [input]: message });
      return false;
    } else {
      setError({ ...error, [input]: "" });
      return true;
    }
  };

  const checkStandardAllValidation = () => {
    let terror = 0;
    var reg;
    if (value.supply * Math.pow(10, value.decimals) > TOTAL_SUPPLY_LIMIT) {
      toast.error("Supply * 10^(decimal) is greater than u64!");
      return false;
    }
    Object.keys(value).forEach((key) => {
      let inputValue;
      switch (key) {
        case "name":
          if (value[key] === "") {
            terror += 1;
          }
          break;
        case "symbol":
          if (value[key] === "") {
            terror += 1;
          }
          break;
        case "decimals":
          inputValue = parseFloat(value[key]);
          reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
          if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
            terror += 1;
          } else if (parseFloat(inputValue) <= 1) {
            terror += 1;
          }
          break;
        case "supply":
          inputValue = parseFloat(value[key]);
          reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
          if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
            terror += 1;
          } else if (parseFloat(inputValue) <= 0) {
            terror += 1;
          }
          break;
        default:
          terror += 0;
          break;
      }
      return true;
    });
    if (terror > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    checkStandardValidation(e.target.name, e.target.value);
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleCreateToken = useCallback(async (e) => {
    e.preventDefault();
    if (!wallet) {
      toast.error("Please connect wallet to create token!");
      return;
    }
    const checked = checkStandardAllValidation();
    if (checked) {
      setCreateloading(true);
      try {
        const mintKeypair = Keypair.generate(); // create a mint account
        const umi = createUmi(connection.rpcEndpoint)
          .use(mplCandyMachine())
          .use(walletAdapterIdentity(wallet.adapter));
        const lamportsToSend = parseFloat(createFee) * LAMPORTS_PER_SOL;
        const sendSolToAdmin = SystemProgram.transfer({
          fromPubkey: wallet.adapter.publicKey,
          toPubkey: ADMIN_PUBKEY,
          lamports: lamportsToSend,
        });
        const txBuilder = transactionBuilder([
          {
            instruction: fromWeb3JsInstruction(sendSolToAdmin),
            signers: [umi.identity],
            bytesCreatedOnChain: 0,
          }
        ]);
        const mint = generateSigner(umi);
        const result = await createAndMint(umi, {
          mint,
          authority: umi.identity,
          name: value.name,
          symbol: value.symbol,
          uri: value.uri,
          sellerFeeBasisPoints: percentAmount(0),
          decimals: value.decimals,
          amount: value.supply * Math.pow(10, value.decimals),
          tokenOwner: wallet.adapter.publicKey,
          tokenStandard: TokenStandard.Fungible,
        }).add(setAuthority(umi, {
          authorityType: AuthorityType.MintTokens,
          newAuthority: null, // Specify the new authority if needed
          owned: mint,
          owner: wallet.adapter.publicKey,
        })).add(setAuthority(umi, {
          authorityType: AuthorityType.FreezeAccount,
          newAuthority: null, // Specify the new authority if needed
          owned: mint,
          owner: wallet.adapter.publicKey,
        }))
          .add(txBuilder)
          .sendAndConfirm(umi);

        if (result) {
          toast.success("Token creation Success!");
          setCreateloading(false);
          navigate(`/token-details?addr=${mint.publicKey}`);
        }
      } catch (e) {
        toast.error("Failed to create token!");
        console.log("Fail on creating token", e);
        setCreateloading(false);
      }
    }

  }, [value, wallet]);

  // Styles for Desktop
  const containerStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    marginTop: '0px',
    marginBottom: '0px',

  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    maxWidth: '1400px',
    background: '#111432',
    borderRadius: '8px',
    padding: '30px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
    border: '1px solid #075985',
  };

  const labelStyle = {
    color: '#6EE7B7',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  };

  const inputStyle = {
    width: '100%',
    height: '45px',
    background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
    borderRadius: '8px',
    border: '2.57px #212554 solid',
    padding: '0 10px',
    color: '#fff',
    fontSize: '14px',
  };

  const tokenTypeLabelStyle = {
    color: '#2F80ED', // pastel blue color
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    marginTop: '0px',
  };

  const descriptionStyle = {
    fontSize: '14px',
    color: '#7B91B0',
    fontFamily: 'Quicksand, sans-serif',
    marginTop: '10px', // Move description closer to the token box
  };

  const tokenBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: '90%',
    background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
    borderRadius: '8px',
    border: '2.57px #212554 solid',
    padding: '10px 15px',
    color: '#fff',
    fontSize: '14px',
    marginBottom: '5px',
  };

  const separatorStyle = {
    marginTop: '15px',
    marginBottom: '35px',
    borderBottom: '0.5px solid #212554',
  };

  const rowStyle = {
    display: 'flex',
    gap: '25px',
    flexWrap: 'wrap',
  };

  const inputContainerStyle = {
    flex: '1 1 calc(50% - 20px)', // Two inputs per row, minus the gap between them
    minWidth: 'calc(50% - 20px)',
  };

  const createButtonStyle = {
    width: '400px',
    height: '40px',
    background: 'linear-gradient(90deg, #6a00ff 0%, #00f0ff 100%)', // Purple to turquoise gradient
    boxShadow: '4px 38px 62px rgba(0, 0, 0, 0.50)',
    borderRadius: '12px',
    color: '#ffffff',
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0px auto 0',
    border: '1px solid #6a00ff', // Matching border color
  };

  const feeInfoContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '400px', // Matching the button width
    marginBottom: '10px', // Add space between fee info and the button
    margin: '0px auto', // Center the fee info container
  };

  const feeInfoTextStyle = {
    fontSize: '0.8rem',
    color: 'white',
  };

  // Mobile-specific styles
  const mobileFormStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '0px',
    width: '100%',
    background: '#111432',
    borderRadius: '8px',
    padding: '10px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
    border: '1px solid #075985',
    marginBottom: '120px', // Add extra space for the navigation bar
  };

  const mobileTokenBoxStyle = {
    ...tokenBoxStyle,
    width: '100%', // Full width for mobile
  };

  const mobileDescriptionStyle = {
    ...descriptionStyle,
    marginTop: '5px', // Reduce gap between token box and description
    fontSize: '10px',

  };

  const mobileLabelStyle = {
    ...labelStyle,
    marginBottom: '5px', // Reduce gap below label
  };

  const mobileInputContainerStyle = {
    width: '100%', // Make inputs full width on mobile
    marginBottom: '0px', // Consistent gap between each input
  };

  const mobileRowStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '0px', // Ensure consistent spacing between rows
  };

  const mobileCreateButtonStyle = {
    width: '100%', // Make the button full width on mobile
    height: '40px', // Adjust height if needed
    background: 'linear-gradient(90deg, #6a00ff 0%, #00f0ff 100%)', // Purple to turquoise gradient
    boxShadow: '4px 38px 62px rgba(0, 0, 0, 0.50)',
    borderRadius: '25px',
    color: '#ffffff',
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '10px', 
    margin: '0px auto 0',
    border: '1px solid #6a00ff', // Matching border color
  };

  const mobileFeeInfoContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%', // Matching the button width
    marginBottom: '10px', // Add space between fee info and the button
    margin: '0px auto', // Center the fee info container
  };

  const mobileFeeInfoTextStyle = {
    fontSize: '0.8rem',
    marginBottom: '10px', // Add space between fee info and the button
    color: 'white',
  };

  const isMobile = window.innerWidth <= 768; // Change based on your breakpoints

  const descriptionContent = (
    <>
      <span style={{ color: '#2F80ED', fontWeight: 'bold' }}>SPL Tokens</span> are secure, auto-verified token contracts that are designed for hassle-free transfers & integration with other platforms. Ideal for any project.
    </>
  );

  return (
    <div style={isMobile ? { overflowY: 'auto' } : containerStyle}>
      <div style={isMobile ? mobileFormStyle : formStyle}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
          <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
            <div style={{ flex: '1' }}>
              <label style={isMobile ? mobileLabelStyle : tokenTypeLabelStyle}>Token Type:</label>
              <div style={isMobile ? mobileTokenBoxStyle : tokenBoxStyle}>SPL Token</div>
            </div>
            <div style={isMobile ? { flex: '1', marginTop: '5px' } : { flex: '1', display: 'flex', alignItems: 'center', height: '100%' }}>
              <div style={isMobile ? mobileDescriptionStyle : descriptionStyle}>
                {descriptionContent}
              </div>
            </div>
          </div>

          <hr style={separatorStyle} />
          <div style={isMobile ? mobileRowStyle : rowStyle}>
            <div style={isMobile ? mobileInputContainerStyle : inputContainerStyle}>
              <label style={isMobile ? mobileLabelStyle : labelStyle}>
                Name<span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                style={inputStyle}
                onChange={(e) => onChangeInput(e)}
                value={value.name}
                type="text"
                name="name"
                placeholder="Eg: Degen Coin"
              />
              <small className="text-danger">{error.name}</small>
              <br />
            </div>
            <div style={isMobile ? mobileInputContainerStyle : inputContainerStyle}>
              <label style={isMobile ? mobileLabelStyle : labelStyle}>
                Symbol<span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                style={inputStyle}
                onChange={(e) => onChangeInput(e)}
                value={value.symbol}
                type="text"
                name="symbol"
                placeholder="Eg: DGN"
              />
              <small className="text-danger">{error.symbol}</small>
              <br />
            </div>
          </div>
          <div style={isMobile ? mobileRowStyle : rowStyle}>
            <div style={isMobile ? mobileInputContainerStyle : inputContainerStyle}>
              <label style={isMobile ? mobileLabelStyle : labelStyle}>
                Decimals<span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                style={inputStyle}
                onChange={(e) => onChangeInput(e)}
                value={value.decimals}
                type="number"
                name="decimals"
                placeholder="Eg: 9"
              />
              <small className="text-danger">{error.decimals}</small>
              <br />
            </div>
            <div style={isMobile ? mobileInputContainerStyle : inputContainerStyle}>
              <label style={isMobile ? mobileLabelStyle : labelStyle}>
                Total Supply<span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                style={inputStyle}
                onChange={(e) => onChangeInput(e)}
                value={value.supply}
                type="number"
                name="supply"
                placeholder="Eg: 1000000000"
              />
              <small className="text-danger">{error.supply}</small>
              <br />
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <label style={isMobile ? mobileLabelStyle : labelStyle}>
              Token URL
            </label>
            <input
              className="form-control"
              style={inputStyle}
              onChange={(e) => onChangeInput(e)}
              value={value.uri}
              type="string"
              name="uri"
              placeholder="Eg: https://image.png"
            />
            <br />
          </div>
        </div>
        <div style={isMobile ? mobileFeeInfoContainerStyle : feeInfoContainerStyle}>
          <div style={isMobile ? mobileFeeInfoTextStyle : feeInfoTextStyle}>Total Cost:</div>
          <div style={isMobile ? mobileFeeInfoTextStyle : feeInfoTextStyle}>0.05 SOL</div>
        </div>
        <Button
          style={isMobile ? mobileCreateButtonStyle : createButtonStyle}
          onClick={(e) => handleCreateToken(e)}
          loading={createloading} // Display loading state on button
        >
          Create Token
        </Button>
      </div>
    </div>
  );
}
