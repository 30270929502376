import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";

import Button from "react-bootstrap-button-loader";
import { formatPrice } from "../../../hooks/helper";
import Context from "./context/Context";
import kycImg from '../../../images/kyc.png';
import auditImg from '../../../images/secure.png';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';

export default function Step4() {
  const { value, btnNextStep, btnPrevStep, setValue } = useContext(Context);
  const { connection } = useConnection();
  const { wallet, publicKey } = useWallet();
  return (
    <div className={`tab-pane ${value.step === 4 ? 'active' : ''}`} role="tabpanel" id="step4">
      <h4 className="text-center">AUDIT, KYC & SAFU badges.</h4>
      {/* New line of text under the header */}
      <p className="text-center">Give users trust & get free marketing.
      Apply on our <a href="https://discord.gg/Hqg4FzjPVz" target="_blank" rel="noopener noreferrer">Discord</a></p>

      <div className="container text-center">
          {/* Increased marginBottom for the image */}
          <img src={`${process.env.PUBLIC_URL}/assets/servicesOverviewImage.png`} alt="Services Overview" style={{ width: '50%', marginBottom: '40px' }} />
          <p style={{ textAlign: 'left', marginLeft: 'auto', marginRight: 'auto', maxWidth: '600px' }}>
              
          </p>
          {/* Unstyled list */}
          <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>Audit badge & Marketing package for $199 (Usually $1500+)</li>
              <li>Audit, KYC badges & Marketing package for $399 (Usually $2500+)</li>
              <li>Created a token on our site? SAFU badge + Logo on defiswap.app (Free)</li>

          </ul>
      </div>
      <div className="text-center">
          <button type="button" className="btn default-btn prev-step" onClick={btnPrevStep}>Back</button>
          <button type="button" className="btn default-btn next-step" onClick={btnNextStep}>Continue</button>
      </div>
    </div>
    );
}