import React from "react";
import Connect from "./Connect";

const Header = ({ setOpen }) => {
  return (
    <React.Fragment>
      <header id="header" style={headerStyle}>
        <Connect setOpen={setOpen} />
      </header>
    </React.Fragment>
  );
};

const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end', // Align children to the right
  padding: '10px 20px',
  backgroundColor: 'transparent',
  width: '100%',
  boxSizing: 'border-box',
  border: 'none', // Remove any border
};

export default Header;
